
import Vue from 'vue';
import {
  rejectInvitation,
  acceptInvitation,
} from '@/service/groupMemberService';
import { IGroupInvitation } from '@/types/group';
import { getInvites } from '@/service/groupService';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';

export default Vue.extend({
  name: 'GroupInvites',
  components: {
    'group-page-header-count': GroupPageHeaderCount,
    ButtonSpinner,
  },
  data() {
    return {
      myInvites: [] as IGroupInvitation[],
    };
  },
  computed: {
    invitationsLength(): number {
      return this.myInvites ? this.myInvites.length : 0;
    },
  },
  async created() {
    if (this.$store.getters['auth/getToken']) {
      this.showInvites(await getInvites());
    }
  },
  methods: {
    showInvites(invites: IGroupInvitation[]) {
      this.$set(this, 'myInvites', invites);
    },
    async handleAccept(groupInvite: IGroupInvitation) {
      await acceptInvitation(groupInvite.inquiry_id);
      this.myInvites.splice(this.myInvites.indexOf(groupInvite), 1);
      window.location.reload();
    },
    async handleReject(groupInvite: IGroupInvitation) {
      await rejectInvitation(groupInvite.inquiry_id);
      this.myInvites.splice(this.myInvites.indexOf(groupInvite), 1);
    },
  },
});
