
import Vue from 'vue';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Buttons/Button.vue';
import { IDetailedGroup } from '@/types/group';
import { deleteUserAlias } from '@/service/groupService';

export default Vue.extend({
  name: 'aliasTableItem',
  components: {
    Modal,
    'ntnui-button': Button,
  },
  props: {
    alias: {
      type: String,
      default: '' as string,
      required: true,
    },
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
  },
  data() {
    return {
      showModal: false as Boolean,
      disabled: true as Boolean,
    };
  },
  methods: {
    toggleDeleteAliasModal() {
      this.$data.showModal = !this.$data.showModal;
    },

    async deleteAlias() {
      try {
        await deleteUserAlias(this.detailedGroup.slug, this.alias);
        this.$notify({
          group: 'memberships_system',
          type: 'success',
          duration: 5000,
          text: `${this.$t('APP.GROUPS.ALIAS.DELETE.SUCCESS')}`,
        });
        setTimeout(() => {
          this.$router.go(0);
        }, 1500);
      } catch (error) {
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 5000,
          text: `${this.$t('ERROR.GROUP.DELETE.ALIAS')}`,
        });
      }
      this.$data.showModal = false;
    },
  },
});
