import store from '../store/store';
import axios from 'axios';
import { refreshToken } from '@/service/auth';

export async function refreshTokenLogic() {
  if (localStorage.getItem('t-date')) {
    const tokenRetrievedDate = localStorage.getItem('t-date');
    if (tokenRetrievedDate !== null) {
      // Parse string to a date object in ms
      const tokenRetrievedDateObject = +Date.parse(tokenRetrievedDate);
      const tenMinutesInMilliseconds = 600000;
      // Set the expiration of the token to 30min
      const tokenExpirationTimeInMilliseconds = tenMinutesInMilliseconds * 3;
      // Set the window for refreshing to 10min
      const tokenRefreshTimeLimit =
        tokenExpirationTimeInMilliseconds - tenMinutesInMilliseconds;
      // Get time since token was fetched
      const timeSinceFetch = +new Date() - tokenRetrievedDateObject;

      // Refresh token if time is between 20 and 30 min.
      if (
        timeSinceFetch <= tokenExpirationTimeInMilliseconds &&
        timeSinceFetch >= tokenRefreshTimeLimit
      ) {
        refreshToken();
      } else if (timeSinceFetch >= tokenExpirationTimeInMilliseconds) {
        store.dispatch('auth/deleteTokens');
        store.dispatch('user/removeUser');
        axios.defaults.headers.common['Authorization'] = '';
      }
    }
  }
}
