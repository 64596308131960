
import Vue from 'vue';
import LoginResetPasswordForm from '@/views/Auth/LoginResetPasswordForm.vue';

export default Vue.extend({
  name: 'ForgotPassword',
  components: {
    LoginResetPasswordForm,
  },
});
