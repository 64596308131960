
import { ITrainerContract, IContractTimeSlot } from '@/types/trainerContract';
import {
  isActive,
  getContractTime,
  getNextTimeslot,
} from '@/utils/trainerContract';
import { getTrainerContracts } from '@/service/trainerContractService';
import Vue from 'vue';
import { IUser } from '@/types/user';
export default Vue.extend({
  data() {
    return {
      contracts: [] as ITrainerContract[],
      nextActiveContract: {} as IContractTimeSlot,
    };
  },
  async created() {
    this.contracts = await getTrainerContracts();
    this.nextActiveContract = await getNextTimeslot(this.contracts)!;
  },
  computed: {
    getUser(): IUser {
      return this.$store.getters['user/getUser'];
    },
  },
  methods: {
    activeTitle(): string {
      return isActive(this.nextActiveContract)
        ? this.$t('APP.PROFILE.TRAINER_CONTRACT.ACTIVE').toString()
        : this.$t('APP.PROFILE.TRAINER_CONTRACT.INACTIVE').toString();
    },
    activeClasses(): string {
      if (isActive(this.nextActiveContract)) return 'contract_container active';
      return 'contract_container inactive';
    },
    getContractTime,
    getContractDateString() {
      let day = this.nextActiveContract.date_from.getDate();
      let month = this.$t(
        `APP.DATE.MONTH.${this.nextActiveContract.date_from.getMonth()}`
      );
      return `${day}. ${month}`;
    },
  },
});
