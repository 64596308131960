import { IContractTimeSlot, ITrainerContract } from '@/types/trainerContract';
import { getWeekdayNumber } from '@/utils/date';

export function getDaysUntil(contract: IContractTimeSlot) {
  const today = new Date();
  const day = getWeekdayNumber(contract.day);
  let diff = day - today.getDay();
  if (diff < 0) diff += 7;
  const contractTime = new Date();
  setDateTime(contractTime, contract.time_to);
  if (diff == 0 && today.getTime() > contractTime.getTime()) return 7;
  return diff % 7;
}
export function calculateContractDate(days_until: number, time: string): Date {
  const date = new Date();
  date.setDate(date.getDate() + days_until);
  setDateTime(date, time);
  return date;
}
export function setDateTime(date: Date, time: string) {
  const time_slotSplitted = time.split(':');
  date.setHours(parseInt(time_slotSplitted[0]));
  date.setMinutes(parseInt(time_slotSplitted[1]));
  date.setSeconds(parseInt(time_slotSplitted[2]));
}

export function isActive(nextValidContract: IContractTimeSlot) {
  const activeTimeMargin = 30;
  const timeNow = new Date().getTime();
  const minutesUntilStart =
    (nextValidContract.date_from.getTime() - timeNow) / 1000 / 60;
  const minutesUntilEnd =
    (nextValidContract.date_to.getTime() - new Date().getTime()) / 1000 / 60;
  if (minutesUntilStart <= activeTimeMargin && minutesUntilEnd >= 0)
    return true;
  return false;
}

export function getContractTime(timeFrom: string, timeTo: string): string {
  const timeFromArr = timeFrom.split(':');
  const timeToArr = timeTo.split(':');
  return `${timeFromArr[0]}:${timeFromArr[1]}-${timeToArr[0]}:${timeToArr[1]}`;
}

export function getNextTimeslot(contracts: ITrainerContract[]) {
  let squashedTimeslotArray = getFlatListTimeslot(contracts);

  squashedTimeslotArray = squashedTimeslotArray.filter(
    (m) => m.expiry_date.getTime() > new Date().getTime()
  );

  squashedTimeslotArray.sort(
    (a, b) => a.date_from.getTime() - b.date_from.getTime()
  );

  return squashedTimeslotArray[0];
}

function getFlatListTimeslot(contracts: ITrainerContract[]) {
  const squashedTimeslotArray: IContractTimeSlot[] = [];
  for (const contract of contracts) {
    for (const time_slot of contract.time_slots) {
      const squashedTimeslot: IContractTimeSlot = {} as IContractTimeSlot;

      squashedTimeslot.group_slug = contract.group_slug;
      squashedTimeslot.expiry_date = new Date(contract.expiry_date);
      squashedTimeslot.gym = time_slot.gym;
      squashedTimeslot.room = time_slot.room;
      squashedTimeslot.time_from = time_slot.time_from;
      squashedTimeslot.time_to = time_slot.time_to;
      squashedTimeslot.day = time_slot.day;

      const days_until = getDaysUntil(squashedTimeslot);

      squashedTimeslot.date_from = calculateContractDate(
        days_until,
        squashedTimeslot.time_from
      );
      squashedTimeslot.date_to = calculateContractDate(
        days_until,
        squashedTimeslot.time_to
      );

      squashedTimeslotArray.push(squashedTimeslot);
    }
  }
  return squashedTimeslotArray;
}
