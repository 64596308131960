
import Vue from 'vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import Input from '@/components/forms/Input.vue';
import { fetchUser, resetPassword } from '@/service/loginService';
import { ITokenPayload } from '@/types/tokenPayload';
import { fetchToken } from '@/service/auth';

export default Vue.extend({
  name: 'UserReset',
  props: {
    propToken: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  components: {
    'ntnui-button-link': ButtonLink,
    'ntnui-button-submit': ButtonSubmit,
    'form-input': Input,
  },
  data() {
    return {
      token: '',
      password_1: '',
      password_2: '',
      loading: false,
    };
  },
  computed: {
    passwordsDifferError(): boolean {
      return this.password_1 !== this.password_2;
    },
    passwordLengthError(): boolean {
      return this.password_1.length < 8;
    },
    submissionIsValid(): boolean {
      return (
        !this.passwordsDifferError && !this.passwordLengthError && !this.loading
      );
    },
  },
  async created() {
    this.token = this.getToken();
  },
  methods: {
    async setPasswordSubmit() {
      if (this.submissionIsValid) {
        try {
          this.loading = true;
          await resetPassword(this.token, this.password_1);
          this.successfulRequest();
        } catch (error: any) {
          const errorCode: String = error.response.data.code;
          const errorMessage = this.$te('APP.RESET.PASSWORD.ERROR.' + errorCode)
            ? 'APP.RESET.PASSWORD.ERROR.' + errorCode
            : 'ERROR.UNKNOWN';
          this.$notify({
            group: 'memberships_system',
            type: 'error',
            duration: 3000,
            text: `${this.$t(errorMessage)}`,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async successfulRequest() {
      const successMessage = this.phoneNumber
        ? 'APP.VERIFY.SUCCESS'
        : 'APP.RESET.PASSWORD.SUCCESS';
      this.$notify({
        group: 'memberships_system',
        type: 'success',
        duration: 3000,
        text: `${this.$t(successMessage)}`,
      });
      if (this.phoneNumber) {
        await this.loginUser();
        this.$router.push('/register');
      } else {
        this.$router.push('/login');
      }
    },
    async loginUser() {
      const payload: ITokenPayload = {
        phone_number: this.phoneNumber,
        password: this.password_1,
      };
      await fetchToken(payload);
      await fetchUser();
    },
    getToken() {
      if (this.propToken) return this.propToken;
      const urlToken = window.location.pathname.split('/').pop();

      return urlToken !== undefined ? urlToken : '';
    },
  },
});
