import { MutationTree } from 'vuex';
import { IUserState, IUser } from '@/store/types';

export const mutations: MutationTree<IUserState> = {
  userLoaded(state, payload: IUser) {
    state.user = payload;
  },

  removeUser(state) {
    state.user = undefined;
  },
};
