import { ActionTree } from 'vuex';
import { IAuthState, IRootState } from '../types';

export const actions: ActionTree<IAuthState, IRootState> = {
  obtainToken({ commit }, data) {
    commit('setToken', data);
  },
  refreshToken({ commit }, data) {
    commit('updateToken', data);
  },
  deleteTokens({ commit }) {
    commit('removeToken');
  },
};
