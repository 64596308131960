
import Vue from 'vue';
import BreadcrumbsVue from '@/components/Breadcrumbs.vue';

import GroupContent from './GroupContent.vue';
import { getGroupRequest } from '@/service/groupService';
import { IGroupData } from '@/types/group';

export default Vue.extend({
  name: 'GroupView',
  components: {
    BreadcrumbsVue,
    GroupContent,
  },
  data(): IGroupData {
    return {
      detailedGroup: undefined,
      previousRouteData: [],
    };
  },
  computed: {
    userGroupAccess(): boolean {
      const boardRoles = [
        'leader',
        'deputy_leader',
        'cashier',
        'board_member',
        'deputy_board_member',
      ];
      if (this.detailedGroup?.membership) {
        return boardRoles.includes(this.detailedGroup.membership.type);
      }
      return false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.previousRouteData = [from.path, from.name];
    });
  },
  async created() {
    this.refreshGroupData();
  },
  methods: {
    async refreshGroupData() {
      const groupName = this.$route.params.id;
      try {
        this.detailedGroup = await getGroupRequest(groupName);
        this.detailedGroup.board_member_access = this.userGroupAccess;
      } catch (error: any) {
        if (error.response?.status === 404) {
          this.$router.push('/not-found');
        }
      }
    },
  },
});
