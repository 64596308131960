var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.container},[_c('group-page-header-count',{class:_vm.$style.header,attrs:{"header":_vm.contentPlaceholder,"numberOfElements":_vm.tableLength}}),_c('div',{class:_vm.$style.tabs},[_c('div',{class:_vm.memberTableTab === 'members'
          ? [_vm.$style.tab, _vm.$style.tab__selected]
          : _vm.$style.tab,on:{"click":() => _vm.setMemberTableTab('members')}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.BUTTON.MEMBERS'))+" ")]),_c('div',{class:_vm.memberTableTab === 'invite'
          ? [_vm.$style.tab, _vm.$style.tab__selected]
          : _vm.$style.tab,on:{"click":() => _vm.setMemberTableTab('invite')}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.BUTTON.INVITE'))+" ")]),_c('div',{class:_vm.memberTableTab === 'request'
          ? [_vm.$style.tab, _vm.$style.tab__selected]
          : _vm.$style.tab,on:{"click":() => _vm.setMemberTableTab('request')}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.BUTTON.REQUEST'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }