
import Vue from 'vue';

export default Vue.extend({
  name: 'FormInput',
  props: {
    input: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    validate: {
      type: Function,
      default: () => {},
    },
    valid: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
      default: 'default',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    initialValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '' as string,
    };
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
});
