
import Vue from 'vue';
import GroupListGroupCard from '@/components/GroupListGroupCard.vue';
import { IGroup, IGroupResponse } from '@/types/group';
import { getGroups } from '@/service/groupService';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { GROUP_CATEGORY } from '@/types/groupMembers';

export default Vue.extend({
  name: 'GroupList',
  components: {
    'group-card': GroupListGroupCard,
    'loading-spinner': LoadingSpinner,
  },
  props: {
    isMyGroups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: '',
      groupPromise: {} as IGroupResponse,
      allCommittees: [] as IGroup[],
      committeeList: [] as IGroup[],
      loading: false as boolean,
      error: false,
      errorMessage: '' as string,
    };
  },
  async created() {
    try {
      this.loading = true;
      this.groupPromise = await getGroups({
        category: GROUP_CATEGORY.Committee,
      });
      this.committeeList = this.groupPromise.results;
    } catch (error: any) {
      this.error = true;
      this.loading = false;
      this.$data.errorMessage =
        this.$t('ERROR.PROFILE.PASSWORD.CHANGE.TITLE') +
        error.response.data.non_field_errors[0];
    } finally {
      this.loading = false;
    }
  },
});
