
import Vue from 'vue';
import CommitteeList from '@/components/GroupLists/CommitteeList.vue';

export default Vue.extend({
  name: 'Committees',
  components: {
    'committee-list': CommitteeList,
    CommitteeList,
  },
});
