
import { getTrainerContracts } from '@/service/trainerContractService';
import { getContractTime } from '@/utils/trainerContract';
import { ITrainerContract } from '@/types/trainerContract';
import { getWeekdayNumber } from '@/utils/date';
import Vue from 'vue';

export default Vue.extend({
  name: 'TrainerContract',
  data() {
    return {
      contracts: [] as ITrainerContract[],
    };
  },
  async created() {
    this.contracts = await this.getTrainerContracts();
  },
  methods: {
    getWeekdayNumber,
    getTrainerContracts,
    getContractTime,
  },
});
