
export default {
  name: 'GroupPageHeaderCount',
  props: {
    numberOfElements: {
      type: Number,
      default: 0,
    },
    header: {
      type: String,
      required: true,
    },
  },
};
