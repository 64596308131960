var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.group_description},[_c('h4',{class:_vm.$style.settings_header},[_vm._v(_vm._s(_vm.description)+":")]),_c('div',{class:_vm.$style.language_selector},[_c('button',{class:[
            _vm.$style.language_button,
            _vm.$style.language_button__left,
            _vm.selectedLanguage === 'en' ? _vm.$style.is__active : undefined,
          ],attrs:{"type":"button"},on:{"click":function($event){return _vm.setActiveLanguage('en')}}},[_vm._v(" ENG ")]),_c('button',{class:[
            _vm.$style.language_button,
            _vm.$style.language_button__right,
            _vm.selectedLanguage === 'nb' ? _vm.$style.is__active : undefined,
          ],attrs:{"type":"button"},on:{"click":function($event){return _vm.setActiveLanguage('nb')}}},[_vm._v(" NOR ")])])]),_c('ntnui-editor',{ref:"editor",class:_vm.$style.text_editor,attrs:{"content":_vm.selectedLanguageText},on:{"change":_vm.updateTextObject}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }