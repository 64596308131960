import { ActionTree } from 'vuex';
import { IUserState, IUser, IRootState } from '../types';

export const actions: ActionTree<IUserState, IRootState> = {
  userLoaded({ commit }, data: IUser): void {
    commit('userLoaded', data);
  },

  removeUser({ commit }): void {
    commit('removeUser');
  },
};
