import request from './request';
import {
  IDetailedGroup,
  IBoardMember,
  IGroupResponse,
  IGroupInvitation,
  IGroupMembershipRecordResults,
  IGroupMembershipRecordParams,
  IGroupMembershipRecord,
} from '@/types/group';
import {
  IGroupMemberRequestParams,
  IGroupInvite,
  IGroupRequestsResponse,
  IGroupMembershipsResponse,
  IGroupCategoryParams,
} from '@/types/groupMembers';

interface IResponseDetail {
  detail: string;
}

export async function joinGroupRequest(
  group: string
): Promise<IResponseDetail> {
  return request({
    method: 'POST',
    url: `/groups/${group}/requests/`,
  });
}

export async function getGroupRequest(group: string): Promise<IDetailedGroup> {
  return request({
    url: `/groups/${group}/`,
  });
}

export async function updateGroupRequest(
  groupName: string,
  detailedGroup: IDetailedGroup
): Promise<IDetailedGroup> {
  return request({
    method: 'PUT',
    url: `/groups/${groupName}/`,
    data: { ...detailedGroup },
  });
}

export async function getGroupMembersRequest(
  group: string,
  params?: IGroupMemberRequestParams
): Promise<IGroupMembershipsResponse> {
  return request({
    url: `/groups/${group}/memberships/`,
    params: { page_size: 200, ...params },
  });
}

export async function sendGroupInviteRequest(
  group: string,
  phoneNumber: string
): Promise<IResponseDetail> {
  return request({
    method: 'POST',
    url: `/groups/${group}/invites/`,
    data: {
      phone_number: phoneNumber,
    },
  });
}

export async function getInvitedUsersForGroupRequest(
  group: string
): Promise<IGroupInvite[]> {
  return request({
    url: `/groups/${group}/invites/`,
  });
}

export async function getUserRequestsForGroupRequests(
  group: string,
  params?: IGroupMemberRequestParams
): Promise<IGroupRequestsResponse> {
  return request({
    url: `/groups/${group}/requests/`,
    params: { ...params, page_size: 7 },
  });
}

export function getGroupBoardRequest(group: string): Promise<IBoardMember[]> {
  return request({
    url: `/groups/${group}/board-members/`,
    params: { page_size: 7 },
  });
}

export async function getGroups(
  params?: IGroupCategoryParams
): Promise<IGroupResponse> {
  return request({
    url: '/groups/',
    params: { ...params, page_size: 100 },
  });
}

export async function getInvites(): Promise<IGroupInvitation[]> {
  return request({
    url: `groups/invites/`,
  });
}

export async function getUserAlias(group_slug: string): Promise<string[]> {
  return request({
    url: `/groups/${group_slug}/alias/`,
  });
}

export async function postUserAlias(
  group_slug: string,
  alias: string
): Promise<string> {
  return request({
    method: `POST`,
    url: `/groups/${group_slug}/alias/`,
    data: { alias_role: alias },
  });
}

export async function deleteUserAlias(
  group_slug: string,
  alias: string
): Promise<IResponseDetail> {
  alias = encodeURIComponent(alias);
  return request({
    method: 'DELETE',
    url: `/groups/${group_slug}/alias/${alias}/`,
  });
}

export async function getGroupMembershipRecords(
  group_slug: string,
  params?: IGroupMembershipRecordParams
): Promise<IGroupMembershipRecordResults> {
  return request({
    url: `/groups/${group_slug}/membership-histories/`,
    params: {
      page_size: params?.page_size || 20,
      page: params?.page,
      year: params?.year,
      is_registered_in_club_admin: params?.is_registered_in_club_admin,
    },
  });
}

export async function putGroupMembershipRecord(
  group_slug: string,
  membership_history_no: number,
  is_registered_in_club_admin: boolean
): Promise<IGroupMembershipRecord> {
  return request({
    method: `PUT`,
    url: `/groups/${group_slug}/membership-histories/${membership_history_no}/`,
    data: { is_registered_in_club_admin: is_registered_in_club_admin },
  });
}

export async function postMembershipRenewal(
  group_slug: string
): Promise<IResponseDetail> {
  return await request({
    method: 'POST',
    url: `/groups/${group_slug}/membership-histories/`,
  });
}

export async function downloadMemberships(
  group_slug: string,
  year: string
): Promise<IResponseDetail> {
  return await request({
    method: 'POST',
    url: `/groups/${group_slug}/download-memberships/${year}/`,
  });
}
