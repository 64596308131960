
import Vue from 'vue';
import SearchFieldVue from '@/components/forms/SearchField.vue';
import GroupMemberTableTabs from './GroupMembersTableTabs.vue';
import GroupMembersInvitationForm from './GroupMembersInvitationForm.vue';
import GroupMemberCard from '@/components/GroupMemberCard.vue';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';
import {
  IGroupMember,
  IGroupRequest,
  IGroupInvite,
  IGroupMemberRequestParams,
} from '@/types/groupMembers';
import {
  getGroupMembersRequest,
  getInvitedUsersForGroupRequest,
  getUserRequestsForGroupRequests,
} from '@/service/groupService';
import { debounce } from 'debounce';
import {
  removeMemberFromGroup,
  removeGroupInvitation,
  removeGroupRequest,
  acceptGroupRequest,
} from '@/service/groupMemberService';

import FuzzySearch from 'fuzzy-search';

interface IMembersResponse {
  count: undefined | number;
  next: undefined | string;
  previous: undefined | string;
  results: IGroupMember[] | IGroupRequest[] | IGroupInvite[] | undefined;
}

export default Vue.extend({
  name: 'GroupMembers',
  components: {
    ButtonSpinner,
    SearchFieldVue,
    GroupMemberTableTabs,
    GroupMembersInvitationForm,
    GroupMemberCard,
  },
  data() {
    return {
      memberTableTab: 'members',
      clickedTableItem: undefined as
        | IGroupMember
        | IGroupRequest
        | IGroupInvite
        | undefined,
      tableItems: undefined as
        | IGroupMember[]
        | IGroupRequest[]
        | IGroupInvite[]
        | undefined,
      searchText: undefined as undefined | string,
      totalItems: undefined as undefined | Number,
      debounceSearch: undefined as undefined | Function,
      nextPageParams: undefined as undefined | IGroupMemberRequestParams,
      previousPageParams: undefined as undefined | IGroupMemberRequestParams,
      previousPage: false,
    };
  },
  computed: {
    showTableItems():
      | IGroupMember[]
      | IGroupRequest[]
      | IGroupInvite[]
      | undefined {
      let tableItems = this.tableItems;
      if (this.memberTableTab !== 'members') {
        const searcher = new FuzzySearch(
          tableItems,
          ['first_name', 'last_name'],
          {
            sort: true,
          }
        );
        tableItems = searcher.search(this.searchText);
      }
      return tableItems;
    },
    tableLength(): Number {
      if (this.totalItems) {
        return this.totalItems;
      } else {
        const tableItems = this.showTableItems;
        return tableItems ? tableItems.length : 0;
      }
    },
    removeText(): string {
      switch (this.memberTableTab) {
        case 'members':
          return this.$t('APP.BOARD.ADMIN.DELETE.MEMBER').toString();
        case 'invite':
          return this.$t('APP.BOARD.ADMIN.DELETE.INVITE').toString();
        case 'request':
          return this.$t('APP.GROUPS.REQUESTS.REJECT').toString();
        default:
          return this.$t('APP.BOARD.ADMIN.DELETE.MEMBER').toString();
      }
    },
  },
  created() {
    this.fetchMembers();
    this.debounceSearch = debounce((search: string) => {
      if (this.memberTableTab === 'members') {
        this.fetchMembers({ search: search });
      }
    }, 500);
  },
  methods: {
    isMember(obj: any): obj is IGroupMember {
      return obj.membership_no !== undefined;
    },
    isInvite(obj: any): obj is IGroupInvite {
      return obj.inquiry_id !== undefined;
    },
    isRequest(obj: any): obj is IGroupRequest {
      return obj.inquiry_id !== undefined;
    },
    userCanDeleteItem(
      tableItem: IGroupMember | IGroupInvite | IGroupRequest
    ): boolean {
      return this.isMember(tableItem) ? tableItem.type === 'member' : true;
    },
    async deleteMember() {
      const groupName = this.$route.params.id;
      if (
        this.memberTableTab === 'members' &&
        this.isMember(this.clickedTableItem)
      ) {
        await removeMemberFromGroup(
          groupName,
          this.clickedTableItem.membership_no
        );
      } else if (
        this.memberTableTab === 'invite' &&
        this.isInvite(this.clickedTableItem)
      ) {
        await removeGroupInvitation({
          groupName,
          inquiry_id: this.clickedTableItem.inquiry_id.toString(),
        });
      } else if (
        this.memberTableTab === 'request' &&
        this.isRequest(this.clickedTableItem)
      ) {
        await removeGroupRequest({
          groupName,
          inquiry_id: this.clickedTableItem.inquiry_id.toString(),
        });
      }
      await this.fetchMembers();
    },
    async acceptRequest() {
      const groupName = this.$route.params.id;
      if (
        this.clickedTableItem != undefined &&
        this.isRequest(this.clickedTableItem)
      ) {
        await acceptGroupRequest({
          groupName,
          inquiry_id: this.clickedTableItem.inquiry_id.toString(),
        });
      }
      await this.fetchMembers();
    },
    async setMemberTableTab(memberTableTab: string) {
      this.memberTableTab = memberTableTab;
      this.fetchMembers();
    },
    setMember(
      clickedTableItem: IGroupMember | IGroupRequest | IGroupInvite | undefined
    ) {
      this.clickedTableItem = clickedTableItem;
    },
    async fetchMembers(params?: IGroupMemberRequestParams) {
      const tableItems = await this.requestTableItems(params);
      this.totalItems = tableItems.count;
      this.nextPageParams = this.parseQueryString(tableItems.next);
      this.previousPage = !!tableItems.previous;
      this.previousPageParams = this.parseQueryString(tableItems.previous);
      this.tableItems = tableItems.results;
    },
    async requestTableItems(
      params?: IGroupMemberRequestParams
    ): Promise<IMembersResponse> {
      const groupName = this.$route.params.id;
      switch (this.memberTableTab) {
        case 'invite':
          return this.requestGroupInvites(groupName);
        case 'request':
          return this.requestGroupRequests(groupName, params);
        default:
          return this.requestGroupMembers(groupName, params);
      }
    },
    async requestGroupInvites(groupName: string): Promise<IMembersResponse> {
      const invitedUsers: IMembersResponse = {
        count: undefined,
        next: undefined,
        previous: undefined,
        results: await getInvitedUsersForGroupRequest(groupName),
      };
      return invitedUsers;
    },
    async requestGroupRequests(
      groupName: string,
      params?: IGroupMemberRequestParams
    ): Promise<IMembersResponse> {
      const groupRequests: IMembersResponse =
        await getUserRequestsForGroupRequests(groupName, params);
      return groupRequests;
    },
    async requestGroupMembers(
      groupName: string,
      params?: IGroupMemberRequestParams
    ): Promise<IMembersResponse> {
      const groupMembersResponse = await getGroupMembersRequest(
        groupName,
        params
      );
      const groupMembers: IMembersResponse = {
        count: groupMembersResponse.count,
        next: groupMembersResponse.next,
        previous: groupMembersResponse.previous,
        results: groupMembersResponse.results,
      };
      return groupMembers;
    },
    parseQueryString(
      urlString: String | undefined
    ): IGroupMemberRequestParams | undefined {
      if (urlString) {
        const parameterString = urlString.split('?')[1];
        if (parameterString) {
          return this.splitParameterToTuple(parameterString);
        }
      }
      return undefined;
    },
    splitParameterToTuple(parameterString: String) {
      return parameterString.split('&').reduce((acc, parameter) => {
        const parameterTuple = parameter.split('=');
        return { ...acc, [parameterTuple[0]]: parameterTuple[1] };
      }, {});
    },
  },
});
