
import Vue from 'vue';
import GroupListGroupCard from '@/components/GroupListGroupCard.vue';
import SearchField from '@/components/forms/SearchField.vue';
import { IGroup } from '@/types/group';
import { getGroups } from '@/service/groupService';
import FuzzySearch from 'fuzzy-search';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { GROUP_CATEGORY } from '@/types/groupMembers';

export default Vue.extend({
  name: 'GroupList',
  components: {
    'group-card': GroupListGroupCard,
    'search-field': SearchField,
    'loading-spinner': LoadingSpinner,
  },
  data() {
    return {
      searchText: '',
      sportsGroupList: [] as IGroup[],
      error: false,
      loading: false,
      errorMessage: '',
    };
  },
  async created() {
    try {
      this.loading = true;
      const groupPromise = await getGroups({
        category: GROUP_CATEGORY.SportsGroup,
      });
      this.sportsGroupList = groupPromise.results;
    } catch (error: any) {
      this.errorMessage = error.response?.data?.detail
        ? this.$t('ERROR.PROFILE.PASSWORD.CHANGE.TITLE') +
          error.response.data.detail
        : this.$t('ERROR.UNKNOWN').toString();
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  computed: {
    sportsGroups(): IGroup[] {
      this.sportsGroupList.forEach((group) => {
        group.name = this.$te('APP.GROUP.' + group.slug.toUpperCase())
          ? this.$tc('APP.GROUP.' + group.slug.toUpperCase())
          : group.name;
      });
      const searcher = new FuzzySearch(this.sportsGroupList, ['name'], {
        sort: true,
      });
      let result = searcher.search(this.searchText);
      result.sort((a: IGroup, b: IGroup) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      return result;
    },
  },
});
