
import Vue from 'vue';
import SearchFieldVue from '@/components/forms/SearchField.vue';
import Button from '@/components/Buttons/Button.vue';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';
import {
  IGroupMember,
  IGroupMemberRequestParams,
  IGroupMembershipsResponse,
} from '@/types/groupMembers';
import FuzzySearch from 'fuzzy-search';
import { debounce } from 'debounce';
import { getGroupMembersRequest } from '@/service/groupService';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import GroupMemberCard from '@/components/GroupMemberCard.vue';
import { changeGroupMembership } from '@/service/groupMemberService';
import GroupBoardModal from '../GroupBoard/GroupBoardModal.vue';
import { IDetailedGroup } from '@/types/group';
import * as _ from 'lodash';

export default Vue.extend({
  name: 'GroupVolunteers',
  components: {
    'ntnui-button': Button,
    SearchFieldVue,
    'group-page-header-count': GroupPageHeaderCount,
    GroupMemberCard,
    'add-member-modal': GroupBoardModal,
    'ntnui-spinner': ButtonSpinner,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
  },
  data() {
    return {
      searchText: undefined as undefined | string,
      tableItems: undefined as IGroupMember[] | undefined,
      debounceSearch: undefined as undefined | Function,
      clickedTableItem: undefined as IGroupMember | undefined,
      memberTableTab: 'volunteers',
      showAddBoardMemberModal: false as boolean,
    };
  },
  computed: {
    showTableItems(): IGroupMember[] | undefined {
      let tableItems = this.tableItems;
      const searcher = new FuzzySearch(
        tableItems,
        ['first_name', 'last_name'],
        {
          sort: true,
        }
      );
      tableItems = searcher.search(this.searchText);
      return tableItems;
    },
    sortedVolunteers(): IGroupMember[] {
      return _.orderBy(
        this.tableItems,
        ['first_name', 'last_name'],
        ['asc', 'asc']
      );
    },
  },
  async created() {
    try {
      this.$data.tableItems = await this.fetchVolunteers({ type: 'volunteer' });
    } catch (error) {
      this.$notify({
        group: 'memberships_system',
        type: 'error',
        duration: 1500,
        text: this.$t('ERROR.GROUP.VOLUNTEERS').toString(),
      });
    }
    this.debounceSearch = debounce(async (search: string) => {
      this.tableItems = await this.fetchVolunteers({
        search: search,
        type: 'volunteer',
      });
    }, 500);
  },
  methods: {
    isVolunteer(obj: IGroupMember | undefined) {
      return obj?.type === 'volunteer';
    },
    toggleAddBoardMemberModal() {
      this.showAddBoardMemberModal = !this.showAddBoardMemberModal;
      if (!this.showAddBoardMemberModal) {
        this.getVolunteers();
      }
    },
    async fetchVolunteers(
      params?: IGroupMemberRequestParams
    ): Promise<IGroupMember[]> {
      const groupName = this.$route.params.id;
      let volunteerResponse = {} as IGroupMembershipsResponse;
      try {
        volunteerResponse = await getGroupMembersRequest(groupName, params);
      } catch (error) {
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 1500,
          text: this.$t('ERROR.GROUP.VOLUNTEERS').toString(),
        });
      }
      return volunteerResponse.results;
    },
    setMember(clickedTableItem: IGroupMember | undefined) {
      this.clickedTableItem = clickedTableItem;
    },
    async getVolunteers() {
      this.$data.tableItems = await this.fetchVolunteers({ type: 'volunteer' });
    },
    async removeVolunteerStatus() {
      const groupName = this.$route.params.id;
      if (
        this.memberTableTab === 'volunteers' &&
        this.isVolunteer(this.clickedTableItem)
      ) {
        await changeGroupMembership(
          groupName,
          this.clickedTableItem!.membership_no,
          {
            type: 'member',
          }
        );
      }
      this.getVolunteers();
    },
  },
});
