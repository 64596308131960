import request from './request';

export default async function postFeedback(
  title: string,
  description: string,
  is_anonymous: boolean
): Promise<any> {
  return request({
    method: 'POST',
    url: `/slack/`,
    data: { title, description, is_anonymous },
  });
}
