var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return ((_vm.inputType)==='checkbox'&&(_vm.shape === 'memberInvite'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.$style.membersInput,attrs:{"autocomplete":_vm.autocomplete,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":function($event){return _vm.$emit('update:input', _vm.value)},"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.validate]}}):((_vm.inputType)==='radio'&&(_vm.shape === 'memberInvite'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.$style.membersInput,attrs:{"autocomplete":_vm.autocomplete,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":function($event){return _vm.$emit('update:input', _vm.value)},"change":[function($event){_vm.value=null},_vm.validate]}}):(_vm.shape === 'memberInvite')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.$style.membersInput,attrs:{"autocomplete":_vm.autocomplete,"type":_vm.inputType},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.value=$event.target.value},function($event){return _vm.$emit('update:input', _vm.value)}],"change":_vm.validate}}):((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.disabled
      ? [_vm.$style.input, _vm.$style.input__disabled]
      : [_vm.$style.input, !_vm.valid && _vm.isError ? _vm.$style.error : ''],attrs:{"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":function($event){return _vm.$emit('update:input', _vm.value)},"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.validate]}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.disabled
      ? [_vm.$style.input, _vm.$style.input__disabled]
      : [_vm.$style.input, !_vm.valid && _vm.isError ? _vm.$style.error : ''],attrs:{"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":function($event){return _vm.$emit('update:input', _vm.value)},"change":[function($event){_vm.value=null},_vm.validate]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:_vm.disabled
      ? [_vm.$style.input, _vm.$style.input__disabled]
      : [_vm.$style.input, !_vm.valid && _vm.isError ? _vm.$style.error : ''],attrs:{"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":_vm.inputType},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.value=$event.target.value},function($event){return _vm.$emit('update:input', _vm.value)}],"change":_vm.validate}})
}
var staticRenderFns = []

export { render, staticRenderFns }