
import Vue from 'vue';
import getButtonProps from '@/utils/buttonDefaultProps';
import { isMobile } from '@/utils/isMobile';
const Spinner = require('vue-simple-spinner');

export default Vue.extend({
  name: 'NtnuiButton',
  components: {
    Spinner,
  },
  props: getButtonProps(),
  data() {
    return {
      helpTextActive: false,
    };
  },
  methods: {
    toggleHelpTextActiveClick() {
      if (isMobile()) {
        this.helpTextActive = !this.helpTextActive;
      }
    },
    toggleHelpTextActiveHover() {
      if (!isMobile()) {
        this.helpTextActive = !this.helpTextActive;
      }
    },
  },
});
