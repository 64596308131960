var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['editor', _vm.$style.editor_wrapper]},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function({ commands, isActive }){return [_c('div',{class:_vm.$style.menubar},[_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.bold() ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":commands.bold}},[_c('i',{class:['fa', 'fa-bold']})]),_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.italic() ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":commands.italic}},[_c('i',{class:['fa', 'fa-italic']})]),_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.underline() ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":commands.underline}},[_c('i',{class:['fa', 'fa-underline']})]),_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.heading({ level: 3 }) ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":function($event){return commands.heading({ level: 3 })}}},[_vm._v(" H3 ")]),_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.bullet_list() ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":commands.bullet_list}},[_c('i',{class:['fa', 'fa-list-ul']})]),_c('button',{class:[
          _vm.$style.menubar__button,
          isActive.ordered_list() ? _vm.$style.is__active : undefined,
        ],attrs:{"type":"button"},on:{"click":commands.ordered_list}},[_c('i',{class:['fa', 'fa-list-ol']})]),_c('button',{class:_vm.$style.menubar__button,attrs:{"type":"button"},on:{"click":commands.undo}},[_c('i',{class:['fa', 'fa-undo']})]),_c('button',{class:_vm.$style.menubar__button,attrs:{"type":"button"},on:{"click":commands.redo}},[_c('i',{class:['fa', 'fa-redo']})])])]}}])}),_c('editor-content',{staticClass:"editor-content",attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }