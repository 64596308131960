
import Vue from 'vue';

import ProfileDescription from '@/views/Profile/ProfileContent/ProfileDescription.vue';
import MyGroupList from '@/components/GroupLists/MyGroupList.vue';
import GroupInvites from '@/components/GroupInvites.vue';

import NTNUIMembershipCard from '@/components/Memberships/NTNUIMembershipCard.vue';

import store from '@/store/store';
import { IUser } from '@/types/user';
import { fetchUser } from '@/service/loginService';
import TrainerContract from '../../components/TrainerContractCard.vue';

export default Vue.extend({
  name: 'Profile',
  components: {
    'group-list': MyGroupList,
    'group-invites': GroupInvites,
    'profile-description': ProfileDescription,
    'ntnui-membership-card': NTNUIMembershipCard,
    TrainerContract,
  },
  data() {
    return {
      user: store.getters['user/getUser'] as IUser,
    };
  },
  async mounted() {
    if (!this.user) {
      this.user = await fetchUser();
    }
  },
});
