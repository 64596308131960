import request from './request';
import router from '@/router';
import store from '@/store/store';
import {
  ITokenPayload,
  ITokenResponse,
  IRefreshTokenResponse,
} from '@/types/tokenPayload';
import axios from 'axios';
import { fetchUser } from '@/service/loginService';

export async function fetchToken(
  tokenPayload: ITokenPayload
): Promise<ITokenResponse> {
  const data: ITokenResponse = await request({
    method: 'POST',
    url: `/token/`,
    data: tokenPayload,
  });
  await store.dispatch('auth/obtainToken', data);
  return data;
}

export async function verifyToken() {
  const token = await store.getters['auth/getToken'];
  if (!token) return;

  try {
    await request({
      method: 'POST',
      url: `/token/verify/`,
      data: { token },
    });
    await fetchUser();
  } catch (error: any) {
    axios.defaults.headers.common['Authorization'] = '';
    await store.dispatch('auth/deleteTokens');
    await store.dispatch('user/removeUser');
    router.go(0);
  }
}

export async function refreshToken(): Promise<
  IRefreshTokenResponse | undefined
> {
  try {
    const refreshToken = localStorage.getItem('r');
    if (!refreshToken) return;
    const data: IRefreshTokenResponse = await request({
      method: 'POST',
      url: `/token/refresh/`,
      data: { refresh: refreshToken },
    });
    await store.dispatch('auth/refreshToken', data);
    return data;
  } catch (error: any) {
    await store.dispatch('auth/deleteTokens');
    await store.dispatch('user/removeUser');
    throw error;
  }
}
