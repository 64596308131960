
import Vue from 'vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';

export default Vue.extend({
  name: 'ProfileDescription',
  components: {
    'ntnui-button-link': ButtonLink,
  },
});
