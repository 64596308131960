
import Vue from 'vue';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import GroupMembershipCard from '@/components/Memberships/GroupMembershipCard.vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import { IGroup, IGroupResponse } from '@/types/group';
import { getGroups } from '@/service/groupService';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default Vue.extend({
  name: 'GroupList',
  components: {
    'group-card': GroupMembershipCard,
    'group-page-header-count': GroupPageHeaderCount,
    'ntnui-button-link': ButtonLink,
    'loading-spinner': LoadingSpinner,
  },
  data() {
    return {
      searchText: '',
      isMyGroups: false,
      myGroups: [] as IGroup[],
      groupPromise: {} as IGroupResponse,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.groupPromise = await getGroups();
    this.loading = false;
    this.filterMyGroups();
  },
  computed: {
    myGroupListLength(): number {
      return this.myGroups ? this.myGroups.length : 0;
    },
  },
  methods: {
    filterMyGroups(): void {
      if (this.groupPromise.results) {
        this.myGroups = this.groupPromise.results.filter(
          (group: IGroup) => group.member
        );
        this.isMyGroups = this.myGroups.length > 0;
      }
    },
  },
});
