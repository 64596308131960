<template>
  <div :class="['editor', $style.editor_wrapper]">
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div :class="$style.menubar">
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.bold() ? $style.is__active : undefined,
          ]"
          @click="commands.bold"
        >
          <i :class="['fa', 'fa-bold']" />
        </button>
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.italic() ? $style.is__active : undefined,
          ]"
          @click="commands.italic"
        >
          <i :class="['fa', 'fa-italic']" />
        </button>
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.underline() ? $style.is__active : undefined,
          ]"
          @click="commands.underline"
        >
          <i :class="['fa', 'fa-underline']" />
        </button>
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.heading({ level: 3 }) ? $style.is__active : undefined,
          ]"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.bullet_list() ? $style.is__active : undefined,
          ]"
          @click="commands.bullet_list"
        >
          <i :class="['fa', 'fa-list-ul']" />
        </button>
        <button
          type="button"
          :class="[
            $style.menubar__button,
            isActive.ordered_list() ? $style.is__active : undefined,
          ]"
          @click="commands.ordered_list"
        >
          <i :class="['fa', 'fa-list-ol']" />
        </button>

        <button
          type="button"
          :class="$style.menubar__button"
          @click="commands.undo"
        >
          <i :class="['fa', 'fa-undo']" />
        </button>
        <button
          type="button"
          :class="$style.menubar__button"
          @click="commands.redo"
        >
          <i :class="['fa', 'fa-redo']" />
        </button>
      </div>
    </editor-menu-bar>
    <editor-content class="editor-content" :editor="editor" />
  </div>
</template>

<script>
import Vue from 'vue';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Underline,
  History,
  Placeholder,
} from 'tiptap-extensions';

export default Vue.extend({
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    content: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new Heading({ levels: [3] }),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty is-editor-empty',
            emptyNodeText: this.placeholder,
            showOnlyCurrent: false,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.$emit('change', getHTML());
        },
      }),
    };
  },
  created() {
    this.setEditorContent(this.$props.content);
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue) {
        this.setEditorContent(newValue);
      },
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    setEditorContent(editorContent) {
      try {
        this.editor.setContent(editorContent);
      } catch (error) {
        this.editor.setContent('');
      }
    },
  },
});
</script>

<style lang="scss">
.editor-content div {
  outline: none;
}
.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
</style>

<style lang="scss" module>
.editor_wrapper {
  padding: rem(5) rem(10) rem(10);
  background-color: $global-primary-background;
}

.menubar {
  border-bottom: rem(1) solid $global-white-text;

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $global-white;
    padding: rem(8) rem(8);
    margin-right: rem(4);
    margin-bottom: rem(5);
    border-radius: rem(3);
    cursor: pointer;

    &:hover {
      background-color: rgba($global-lightgray-hover, 0.3);
    }

    &.is__active {
      background-color: rgba($global-lightgray-hover, 0.7);
    }
  }
}
</style>
