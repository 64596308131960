export interface IGroupInvite {
  inquiry_id: number;
  email: string;
  first_name: string;
  last_name: string;
  ntnui_no: number;
}

export interface IGroupRequestsResponse {
  count: number;
  next: string;
  previous: string;
  results: IGroupRequest[];
}

export interface IGroupRequest {
  inquiry_id: number;
  group: number;
  first_name: string;
  last_name: string;
  email: string;
  date_created: string;
}

export interface IGroupMembershipsResponse {
  count: number;
  next: string;
  previous: string;
  results: IGroupMember[];
}

export interface IGroupMember {
  first_name: string;
  last_name: string;
  email: string;
  ntnui_no: string;
  group_expiry: string;
  type: string;
  membership_no: number;
}

export type Role =
  | 'leader'
  | 'deputy_leader'
  | 'cashier'
  | 'board_member'
  | 'deputy_board_member'
  | 'volunteer'
  | 'member';

export interface IGroupRelation {
  groupName: string;
  inquiry_id: string;
}

export interface IChangeGroupMembershipRequest {
  type: string;
  group_expiry?: string;
}

export interface IChangeGroupMemberhipResponse {
  detail: string;
  membership: IGroupMember;
}

export interface IGroupMemberRequestParams {
  type?: string;
  search?: string;
  limit?: number;
  offset?: number;
  page?: number;
  page_size?: number;
}

export interface IGroupVolunteerRequestParams {
  search?: string;
}

export enum GROUP_CATEGORY {
  SportsGroup = 'sports_group',
  Committee = 'committee',
}

export interface IGroupCategoryParams {
  category: string;
}
