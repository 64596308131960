
import Vue from 'vue';
import * as dateFns from 'date-fns';
import { IMembershipCardData } from '@/types/group';
import { IUser } from '@/types/user';
import { getDateString } from '@/utils/date';
import Tooltip from '../Tooltip.vue';
export default Vue.extend({
  name: 'membership-card',
  props: {
    membershipName: {
      type: String,
      required: true,
    },
    user: {
      type: Object as () => IUser,
      required: false,
      default: null,
    },
    isGroupMemberCard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    tooltip: Tooltip,
  },
  data(): IMembershipCardData {
    return {
      membershipExpiryDate: new Date(),
      isExpired: false,
      isNearExpired: false,
    };
  },
  created() {
    if (this.isGroupMemberCard && this.user.ntnui_contract_expiry_date) {
      this.createMembershipExpiryDate(this.user.ntnui_contract_expiry_date);
    } else if (!this.isGroupMemberCard && this.user.contract_expiry_date) {
      this.createMembershipExpiryDate(this.user.contract_expiry_date);
    } else {
      this.isExpired = true;
    }
  },
  methods: {
    createMembershipExpiryDate(expiry: string) {
      if (this.user && expiry !== null) {
        this.membershipExpiryDate = new Date(expiry);
        const today = new Date();
        const nearExpiry = dateFns.subDays(this.membershipExpiryDate, 30);

        if (today > this.membershipExpiryDate) {
          this.isExpired = true;
        } else if (today > nearExpiry) {
          this.isNearExpired = true;
        }
      } else {
        this.isExpired = true;
      }
    },
  },
  computed: {
    expiryDateString(): string {
      return getDateString(this.membershipExpiryDate);
    },
  },
});
