
import Button from '@/components/Buttons/Button.vue';
import { IGroupMembershipRecord } from '@/types/group';
import Vue from 'vue';
export default Vue.extend({
  name: 'MembershipRegisterComponent',
  components: { 'ntnui-button': Button },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    member: {
      type: Object as () => IGroupMembershipRecord,
      required: true,
    },
    membershipIndex: {
      type: Number,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonFunction: {
      type: Function,
      required: true,
    },
    expandBoxes: {
      type: Function,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    showMoreInfo() {
      this.expandBoxes(this.membershipIndex, this.member);
    },
    triggerButtonFunction(member: IGroupMembershipRecord) {
      this.loading = true;
      this.buttonFunction(member).then(() => {
        this.loading = false;
      });
    },
    genderIcon(gender: string): string {
      if (gender === 'M') {
        return 'male';
      } else if (gender === 'F') {
        return 'female';
      } else {
        return '';
      }
    },
  },
});
