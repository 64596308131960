var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.wrapper_top_mobile},[_c('router-link',{attrs:{"to":'/'}},[_c('em',{class:['fas fa-arrow-circle-left', _vm.$style.back_icon]})]),_c('div',[(_vm.detailedGroup.member && _vm.activePage === 'frontpage')?_c('h4',{class:_vm.$style.member_title},[_vm._v(" "+_vm._s(_vm.$t( 'APP.MEMBERSHIP.TYPE.' + this.detailedGroup.membership.type.toUpperCase() ))+" ")]):_vm._e(),_c('h3',{class:_vm.$style.mobile_title},[_vm._v(_vm._s(_vm.activePageHeader))])])],1),_c('div',{class:_vm.$style.button_wrapper},[(_vm.hasAccess)?_c('button',{class:[
        _vm.$style.page_button,
        _vm.$style.page_button__left,
        _vm.activePage === 'frontpage' ? _vm.$style.is__active : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('frontpage')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.FRONTPAGE'))+" ")]):_vm._e(),(_vm.detailedGroup.board_member_access)?_c('button',{class:[
        _vm.$style.page_button,
        _vm.activePage === 'members' ? _vm.$style.is__active : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('members')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.MEMBERS'))+" ")]):_vm._e(),(_vm.detailedGroup.board_member_access)?_c('button',{class:[
        _vm.$style.page_button,
        _vm.activePage === 'volunteers' ? _vm.$style.is__active : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('volunteers')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.VOLUNTEERS'))+" ")]):_vm._e(),(_vm.detailedGroup.board_member_access)?_c('button',{class:[
        _vm.$style.page_button,
        _vm.activePage === 'membership_registering'
          ? _vm.$style.is__active
          : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('membership_registering')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.MEMBERSHIP_REGISTERING'))+" ")]):_vm._e(),(_vm.detailedGroup.board_member_access)?_c('button',{class:[
        _vm.$style.page_button,
        _vm.activePage === 'settings' ? _vm.$style.is__active : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('settings')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.SETTINGS'))+" ")]):_vm._e(),(_vm.hasAccess)?_c('button',{class:[
        _vm.$style.page_button__right,
        _vm.$style.page_button,
        _vm.activePage === 'alias' ? _vm.$style.is__active : undefined,
      ],on:{"click":function($event){return _vm.setActivePage('alias')}}},[_vm._v(" "+_vm._s(_vm.$t('APP.GROUPS.ALIAS'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }