
import Vue from 'vue';
import GroupBoard from '@/views/Group/GroupBoard/GroupBoard.vue';
import GroupLeave from '@/views/Group/GroupJoinLeave/GroupLeave.vue';
import GroupJoin from '@/views/Group/GroupJoinLeave/GroupJoin.vue';
import { getDateString } from '@/utils/date';
import Modal from '@/components/Modal.vue';
import { postMembershipRenewal } from '@/service/groupService';
import { IDetailedGroup } from '@/types/group';
import Button from '@/components/Buttons/Button.vue';

export default Vue.extend({
  name: 'GroupFrontpage',
  components: {
    GroupBoard,
    GroupLeave,
    GroupJoin,
    'double-check-modal': Modal,
    'ntnui-button': Button,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      default: undefined,
    },
    refreshGroupData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      group: undefined as IDetailedGroup | undefined,
      groupExpiryDate: new Date() as Date,
      isExpired: false as boolean,
      isMissingContract: false as boolean,
      showModal: false as boolean,
      websiteURL: '' as string,
    };
  },
  async created() {
    if (this.detailedGroup?.membership?.group_expiry) {
      this.groupExpiryDate = new Date(
        this.detailedGroup.membership.group_expiry
      );
      const today = new Date();
      const expiry = new Date(this.groupExpiryDate);
      if (today > expiry) {
        this.isExpired = true;
      }
    } else {
      //Cannot set date to null, hence creating a default date.
      this.groupExpiryDate = new Date();
      this.isExpired = false;
      this.isMissingContract = true;
    }
  },
  methods: {
    toggleRemoveModal() {
      this.showModal = !this.showModal;
    },
    loadWebsiteURL() {
      const url = this.detailedGroup.website_link;
      window.location.href = url;
    },
    async renewMembership() {
      try {
        await postMembershipRenewal(this.detailedGroup.slug);
        this.$notify({
          group: 'memberships_system',
          type: 'success',
          duration: 5000,
          text: `${this.$t('APP.MODAL.RENEW.SUCCESS')}`,
        });
        location.reload();
      } catch (error: any) {
        let text = `${this.$t('APP.MODAL.RENEW.ERROR')}`;
        switch (error.response.data.code) {
          case 'MISSING_CONTRACT':
            text = `${this.$t('APP.MODAL.RENEW.ERROR.MISSING_CONTRACT')}`;
            break;
          case 'ALREADY_REGISTERED':
            text = `${this.$t('APP.MODAL.RENEW.ERROR.ALREADY_REGISTERED')}`;
            break;
        }
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 5000,
          text,
        });
      }
    },
  },
  computed: {
    currentLanguageDescription(): string {
      switch (this.$i18n.locale) {
        case 'en':
          return this.detailedGroup.description_english;
        default:
          return this.detailedGroup.description_norwegian;
      }
    },
    member(): boolean {
      return this.detailedGroup.member ? true : false;
    },
    upperCaseGroupName(): string {
      return this.detailedGroup.name.toUpperCase();
    },
    expiryDateString(): string {
      return getDateString(this.groupExpiryDate);
    },
    type(): string | undefined {
      return this.detailedGroup.membership?.type;
    },
  },
});
