
import Vue from 'vue';
import SportGroupList from '@/components/GroupLists/SportGroupList.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    'sport-group-list': SportGroupList,
  },
});
