
import Vue from 'vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import Input from '@/components/forms/Input.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import { ISendActivateUserSMSPayload } from '@/types/user';
import { verifyUserBySMS } from '@/service/loginService';
import GlobalError from '@/components/GlobalError.vue';
import UserReset from '@/views/UserReset.vue';

export default Vue.extend({
  name: 'VerifyPhonenumber',
  components: {
    'ntnui-button-submit': ButtonSubmit,
    'ntnui-button-link': ButtonLink,
    'global-error': GlobalError,
    'form-phone-input': PhoneInput,
    'form-input': Input,
    'form-set-password': UserReset,
  },
  data() {
    return {
      phoneNumber: '',
      verificationCode: '',
      phoneNumberValid: false,
      error: '',
      loading: false,
      verificationStep: false,
      setPasswordToken: '',
    };
  },
  computed: {
    isVerificationCodeValid(): Boolean {
      const isCodeNumerical = /^\d+$/.test(this.verificationCode);
      return isCodeNumerical && this.verificationCode.length === 6;
    },
  },
  methods: {
    handleSuccessfulVerification() {
      if (this.verificationStep) {
        this.$notify({
          group: 'memberships_system',
          type: 'success',
          duration: 5000,
          text: `${this.$t('APP.VERIFY.CODE.APPROVED')}`,
        });
      } else {
        this.verificationStep = true;
        this.$notify({
          group: 'memberships_system',
          type: 'success',
          duration: 5000,
          text: `${this.$t('APP.VERIFY.CODE.SENT') + this.phoneNumber}`,
        });
      }
    },
    handleFormSubmission() {
      if (!this.phoneNumberValid && !this.verificationStep) {
        this.error = `${this.$t('ERROR.RESET.USER.INVALID.PHONE')}`;
        setTimeout(() => (this.error = ''), 8000);
      } else if (!this.isVerificationCodeValid && this.verificationStep) {
        this.error = `${this.$t('ERROR.VERIFY.CODE.INVALID')}`;
        setTimeout(() => (this.error = ''), 8000);
      } else if (!this.loading) {
        this.sendVerifyUserRequest();
      }
    },
    async sendVerifyUserRequest() {
      const payload: ISendActivateUserSMSPayload = {
        phone_number: this.phoneNumber,
        verification_code: this.verificationCode,
      };
      try {
        this.loading = true;
        const verificationResponse = await verifyUserBySMS(payload);
        if (verificationResponse.token && verificationResponse.phone_number) {
          this.setPasswordToken = verificationResponse.token;
          this.phoneNumber = verificationResponse.phone_number;
        }
        this.handleSuccessfulVerification();
      } catch (error) {
        this.showErrorNotification(error);
      } finally {
        this.loading = false;
      }
    },
    showErrorNotification(error: any) {
      const errorStatus: String = error.response.status;
      const errorMessage = this.$te('ERROR.VERIFY.USER.' + errorStatus)
        ? 'ERROR.VERIFY.USER.' + errorStatus
        : 'ERROR.UNKNOWN';
      this.$notify({
        group: 'memberships_system',
        type: 'error',
        duration: 8000,
        text: `${this.$t(errorMessage)}`,
      });
    },
  },
});
