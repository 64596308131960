import axios from 'axios';

interface IGroupAdmission {
  _id: number;
  name: string;
  slug: string;
  access_roles: string[];
  accepts_admissions: boolean;
}

export async function getGroupAdmission(): Promise<IGroupAdmission[] | null> {
  try {
    const request = await axios.get('https://api.opptak.ntnui.no/committees');
    if (request.status == 200) {
      return request.data;
    } else return null;
  } catch {
    return null;
  }
}

export async function getAdmissionStatus(): Promise<string | null> {
  try {
    const request = await axios.get(
      'https://api.opptak.ntnui.no/applications/period/'
    );
    if (request.status == 200) return request.data.admissionStatus;
  } catch {}
  return null;
}
