import vue from 'vue';
import vueI18n from 'vue-i18n';

vue.use(vueI18n);

export default new vueI18n({
  locale:
    localStorage.getItem('language') ||
    navigator.language ||
    process.env.VUE_APP_I18N_LOCALE ||
    'no',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'no',
  messages: {
    'nb-NO': require('./locales/no.json'),
    nn: require('./locales/no.json'),
    nb: require('./locales/no.json'),
    no: require('./locales/no.json'),
    en: require('./locales/en.json'),
    'en-US': require('./locales/en.json'),
  },
});
