
import Vue from 'vue';
export default Vue.extend({
  name: 'NavItem',
  props: {
    to: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    sidebar: {
      type: Boolean,
      required: false,
    },
  },
});
