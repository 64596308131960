
import Vue from 'vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import Input from '@/components/forms/Input.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import FormSwitch from '@/components/forms/Switch.vue';
import postFeedback from '@/service/feedbackService';
export default Vue.extend({
  name: 'BugReport',
  components: {
    'form-input': Input,
    'ntnui-button-submit': ButtonSubmit,
    'ntnui-button-link': ButtonLink,
    FormSwitch,
  },
  data() {
    return {
      title: '' as string,
      description: '' as string,
      isAnonymous: false,
      loading: false as Boolean,
    };
  },
  computed: {
    isDisabled(): Boolean {
      return this.title === '' || this.loading;
    },
    isLoggedIn(): boolean {
      return this.$store.getters['auth/getToken'];
    },
  },
  methods: {
    updateAnonymous(data: boolean) {
      this.isAnonymous = data;
    },
    async sendBugReport() {
      if (!this.title) {
        this.$notify({
          group: 'memberships_system',
          type: 'warning',
          duration: 3000,
          text: this.$t('APP.BUG_REPORT_TITLE_ERROR').toString(),
        });
      } else {
        this.loading = true;

        try {
          await postFeedback(this.title, this.description, this.isAnonymous);
          // Clear fields
          (this.$refs.feedbackFormTitle as HTMLInputElement).value = '';
          this.title = '';
          this.description = '';
          this.$notify({
            group: 'memberships_system',
            type: 'success',
            duration: 8000,
            text: this.$tc('APP.BUG_REPORT.SENTFEEDBACK_SUCCESS'),
          });
        } catch (error) {
          this.$notify({
            group: 'memberships_system',
            type: 'error',
            duration: 3000,
            text: this.$tc('APP.BUG_REPORT.SENTFEEDBACK_ERROR'),
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
