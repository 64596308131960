
import Vue from 'vue';
import Modal from '@/components/Modal.vue';
import GroupMemberCard from '@/components/GroupMemberCard.vue';
import GroupBoardModal from './GroupBoardModal.vue';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import Button from '@/components/Buttons/Button.vue';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';
import { getGroupBoardRequest } from '@/service/groupService';
import { IBoardMember, IDetailedGroup } from '@/types/group';
import { changeGroupMembership } from '@/service/groupMemberService';

export default Vue.extend({
  name: 'GroupBoard',
  components: {
    Modal,
    GroupBoardModal,
    'group-page-header-count': GroupPageHeaderCount,

    'ntnui-button': Button,
    GroupMemberCard,
    ButtonSpinner,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
    refreshGroupData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      boardMembers: undefined as undefined | IBoardMember[],
      showAddBoardMemberModal: false as boolean,
      clickedTableItem: undefined as undefined | IBoardMember,
      showModal: false as boolean,
    };
  },
  computed: {
    getUserAccess(): string {
      if (this.detailedGroup.membership) {
        return this.detailedGroup.membership.type;
      }
      return 'notMember';
    },
    boardLength(): number {
      return this.boardMembers ? this.boardMembers.length : 0;
    },
    sortedBoardMembers(): IBoardMember[] {
      const roles = [
        'leader',
        'deputy_leader',
        'cashier',
        'board_member',
        'deputy_board_member',
      ];
      if (this.boardMembers == null) return [] as IBoardMember[];
      return this.boardMembers
        .slice()
        .sort(function (
          boardMember1: IBoardMember,
          boardMember2: IBoardMember
        ) {
          if (
            roles.indexOf(boardMember1.type) > roles.indexOf(boardMember2.type)
          ) {
            return 1;
          }
          if (
            roles.indexOf(boardMember1.type) < roles.indexOf(boardMember2.type)
          ) {
            return -1;
          }
          if (
            boardMember1.first_name + boardMember1.last_name <
            boardMember2.first_name + boardMember2.last_name
          ) {
            return -1;
          }
          if (
            boardMember1.first_name + boardMember1.last_name >
            boardMember2.first_name + boardMember2.last_name
          ) {
            return 1;
          } else {
            return 0;
          }
        });
    },
  },
  async created() {
    this.getGroupBoard();
  },
  methods: {
    toggleAddBoardMemberModal(): void {
      this.showAddBoardMemberModal = !this.showAddBoardMemberModal;
      if (!this.showAddBoardMemberModal) {
        this.getGroupBoard();
      }
    },
    async getGroupBoard() {
      const groupName = this.$route.params.id;
      this.boardMembers = await getGroupBoardRequest(groupName);
    },
    setMember(clickedTableItem: undefined | IBoardMember) {
      this.clickedTableItem = clickedTableItem;
    },
    async removeBoardMember() {
      const groupName = this.$route.params.id;
      if (
        this.clickedTableItem != undefined &&
        this.clickedTableItem.membership_no
      ) {
        await changeGroupMembership(
          groupName,
          this.clickedTableItem.membership_no,
          {
            type: 'member',
            group_expiry: this.clickedTableItem.group_expiry,
          }
        );
      }
      await this.getGroupBoard();
      this.toggleRemoveModal();
    },
    toggleRemoveModal() {
      this.showModal = !this.showModal;
    },
  },
});
