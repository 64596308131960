
import Vue from 'vue';
import Input from '@/components/forms/Input.vue';
import GroupSettingsTextEditor from './GroupSettingsTextEditor.vue';
import { IDetailedGroup } from '@/types/group';
import { updateGroupRequest } from '@/service/groupService';
import Switch from '@/components/forms/Switch.vue';
import ButtonSpinner from '../../../components/Buttons/ButtonSpinner.vue';

export default Vue.extend({
  name: 'GroupSettings',
  components: {
    GroupSettingsTextEditor,
    'form-switch': Switch,
    'form-input': Input,
    ButtonSpinner,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
  },
  data() {
    return {
      logoImg: null as ArrayBuffer | null | string,
      fallbackLogo: false,
      changedLogo: false,
      isPublicGroupAccess: false,
      loading: false,
      website: '',
      descriptionEnglish: '',
      descriptionNorwegian: '',
      errorMessage: '',
      isWebsiteUrlValid: true,
    };
  },
  created() {
    this.isPublicGroupAccess = this.detailedGroup.access === 'C';
    this.descriptionEnglish = this.detailedGroup.description_english;
    this.descriptionNorwegian = this.detailedGroup.description_norwegian;
  },
  methods: {
    updateGroupDescription(description: any) {
      switch (description.locale) {
        case 'en':
          this.descriptionEnglish = description.value;
          break;
        default:
          this.descriptionNorwegian = description.value;
      }
    },
    updateGroupAccess(isPublicGroupAccess: boolean) {
      this.isPublicGroupAccess = isPublicGroupAccess;
    },
    async updateGroup() {
      const updatedGroup = this.detailedGroup as IDetailedGroup;
      updatedGroup.description_english = this.descriptionEnglish;
      updatedGroup.description_norwegian = this.descriptionNorwegian;
      updatedGroup.access = this.isPublicGroupAccess ? 'C' : 'O';
      updatedGroup.website_link = this.validateUrl(this.website);
      try {
        await updateGroupRequest(updatedGroup.slug, updatedGroup);
        this.isWebsiteUrlValid = true;
      } catch (error: any) {
        if (error.response?.data.code == 'INVALID_URL') {
          this.errorMessage = this.$t(
            'APP.GROUPS.SETTINGS.WEBSITE.ERROR.INVALID_URL'
          ).toString();
          this.isWebsiteUrlValid = false;
        } else this.errorMessage = '';
        throw error;
      }
    },
    validateUrl(url: string): string {
      url = url.trim();
      if (url == null || url === '') {
        return '';
      }
      if (url.toLowerCase().startsWith('http://')) {
        return url.replace(/http:/gi, 'https:');
      }
      if (!url.toLowerCase().startsWith('https://')) {
        return 'https://' + url;
      }
      return url;
    },
  },
});
