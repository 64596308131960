
import Vue from 'vue';
import SelectVue from 'vue-select';
import { debounce } from 'debounce';
import Button from '@/components/Buttons/Button.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import ModalVue from '@/components/Modal.vue';
import { changeGroupMembership } from '@/service/groupMemberService';
import { getGroupMembersRequest } from '@/service/groupService';
import { getRoleTranslation } from '@/utils/roleTranslation';
import { IGroupMember, Role } from '@/types/groupMembers';
interface IGroupBoardModalData {
  roleDropdownValue: undefined | string;
  memberDropdownValue: undefined | string | null;
  members: undefined | string[];
  memberObjects: undefined | IGroupMember[];
  debounceSearch: undefined | Function;
  roles: Object;
  loading: boolean;
  memberNotFound: boolean;
}
export default Vue.extend({
  name: 'GroupBoardModal',
  components: {
    ModalVue,
    'ntnui-button-submit': ButtonSubmit,
    'ntnui-button': Button,
    'vue-select': SelectVue,
  },
  props: {
    showAddBoardMemberModal: {
      type: Boolean,
      required: true,
    },
    toggleAddBoardMemberModal: {
      type: Function,
      required: true,
    },
    userAccess: {
      type: String,
      default: undefined,
    },
  },
  data(): IGroupBoardModalData {
    return {
      roleDropdownValue: undefined,
      memberDropdownValue: undefined,
      members: undefined,
      memberObjects: undefined,
      debounceSearch: undefined,
      roles: {
        leader: `${this.$t('APP.BOARD.MEMBERS.ROLE.LEADER')}`,
        deputy_leader: `${this.$t('APP.BOARD.MEMBERS.ROLE.VICEPRESIDENT')}`,
        cashier: `${this.$t('APP.BOARD.MEMBERS.ROLE.CASHIER')}`,
        board_member: `${this.$t('APP.BOARD.MEMBERS.ROLE.BOARDMEMBER')}`,
        deputy_board_member: `${this.$t('APP.BOARD.MEMBERS.ROLE.DEPUTY')}`,
        volunteer: `${this.$t('APP.BOARD.MEMBERS.ROLE.VOLUNTEER')}`,
      },
      loading: false,
      memberNotFound: false,
    };
  },
  computed: {
    isValidForm(): boolean {
      return this.memberDropdownValue === undefined;
    },
    selectedRole(): string | undefined {
      return this.getKeyByValue(this.roles, this.roleDropdownValue as string);
    },
    emptyMembersTable(): boolean {
      return this.members ? this.members.length === 0 : true;
    },
  },
  created() {
    this.debounceSearch = debounce(
      (search: string) => this.searchMember(search),
      500
    );
    if (this.userAccess !== 'leader') {
      this.roles = {
        volunteer: `${this.$t('APP.BOARD.MEMBERS.ROLE.VOLUNTEER')}`,
      };
    }
  },
  methods: {
    getKeyByValue(object: any, value: String): string | undefined {
      return Object.keys(object).find((key) => object[key] === value);
    },
    changeRoleDropdownValue(event: any) {
      this.roleDropdownValue = event.target.value;
    },
    async searchMember(search: string) {
      const selectedRole = this.selectedRole;
      if (search && selectedRole) {
        const members = await getGroupMembersRequest(this.$route.params.id, {
          search: search,
          page_size: 7,
        });
        this.memberObjects = members.results;
        this.members = members.results
          .filter(
            (member: IGroupMember) =>
              member.type !== selectedRole[0] && member.type !== 'leader'
          )
          .map(
            (member: IGroupMember) => member.first_name + ' ' + member.last_name
          );
      }
    },
    async addBoardMember() {
      this.loading = true;
      if (!this.memberDropdownValue) {
        this.memberNotFound = true;
      }
      if (
        this.roleDropdownValue &&
        this.memberDropdownValue &&
        this.memberObjects
      ) {
        this.memberNotFound = false;
        const selectedMember = this.memberObjects.find(
          (member) =>
            member.first_name + ' ' + member.last_name ===
            this.memberDropdownValue
        );
        const groupName = this.$route.params.id;
        const selectedRole = this.selectedRole;
        if (selectedMember && selectedRole) {
          try {
            await changeGroupMembership(
              groupName,
              selectedMember.membership_no,
              {
                group_expiry: selectedMember.group_expiry,
                type: selectedRole,
              }
            );
            this.$notify({
              group: 'memberships_system',
              type: 'success',
              duration: 3000,
              text: this.$t('APP.BOARD.MEMBERSHIP.REQUESTS', {
                name:
                  selectedMember.first_name + ' ' + selectedMember.last_name,
                role: getRoleTranslation(selectedRole as Role).toLowerCase(),
              }).toString(),
            });
            this.memberDropdownValue = null;
          } catch (error: any) {
            this.$notify({
              group: 'memberships_system',
              type: 'error',
              duration: 3000,
              text: this.$t('APP.BOARD.MEMBERSHIP.REQUESTS.ERROR', {
                name:
                  selectedMember.first_name + ' ' + selectedMember.last_name,
                role: getRoleTranslation(selectedRole as Role).toLowerCase(),
              }).toString(),
            });
          }
        }
      }
      this.loading = false;
    },
    toggleModal() {
      this.roleDropdownValue = undefined;
      this.memberDropdownValue = undefined;
      this.members = undefined;
      this.memberObjects = undefined;
      return this.$props.toggleAddBoardMemberModal();
    },
  },
});
