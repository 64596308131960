
import Vue from 'vue';
import { IBreadCrumbsData } from '@/types/breadcrumbs';
export default Vue.extend({
  props: {
    previousRouteData: {
      type: Array,
      required: true,
    },
  },
  data(): IBreadCrumbsData {
    return {
      breadcrumbs: ['DEFAULT', 'PROFILE', 'GROUPS', 'SETTINGS', 'COMMITTEES'],
    };
  },
  computed: {
    routeArray(): string[] {
      return this.$route.fullPath.split('/').filter(Boolean);
    },
  },
  methods: {
    getBreadcrumbName(index: number): string {
      var breadcrumbName = '';
      const routeString = this.routeArray[index].toUpperCase();
      if (this.breadcrumbs.includes(routeString)) {
        breadcrumbName = this.$t('APP.BREADCRUMBS.' + routeString).toString();
      } else {
        breadcrumbName = this.$t('APP.GROUP.' + routeString).toString();
      }
      return breadcrumbName.toUpperCase();
    },
    getBreadcrumbLink(index: number): string {
      var link = '';
      for (let i = 0; i < index + 1; i++) {
        link += '/' + this.routeArray[i];
      }
      return link;
    },
  },
});
