import { GetterTree } from 'vuex';
import { IAuthState, IRootState } from '../types';

export const getters: GetterTree<IAuthState, IRootState> = {
  getToken(state): string | null {
    const { tokens } = state;
    if (tokens !== undefined && tokens !== null) {
      return tokens.access;
    }
    return '';
  },
};
