
import Vue from 'vue';
import Button from '@/components/Buttons/Button.vue';
import { IDetailedGroup } from '@/types/group';
import Input from '@/components/forms/Input.vue';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import Modal from '@/components/Modal.vue';
import AliasTableItem from './AliasTableItem.vue';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';
import { getUserAlias, postUserAlias } from '@/service/groupService';

export default Vue.extend({
  name: 'GroupMemberAlias',
  components: {
    Modal,
    'ntnui-button': Button,
    'header-count': GroupPageHeaderCount,
    'input-field': Input,
    'button-spinner': ButtonSpinner,
    'alias-table-item': AliasTableItem,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
  },

  data() {
    return {
      alias: '',
      successMessage: '',
      errorMessage: '',
      user_alias: [] as string[],
      showModal: false,
      disabled: true,
    };
  },

  methods: {
    toggleAliasModal() {
      if (!this.alias) {
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 5000,
          text: `${this.$t('ERROR.GROUP.ALIAS.MISSING')}`,
        });
      } else if (!this.isValidAlias(this.alias)) {
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 5000,
          text: `${this.$t('ERROR.GROUP.ALIAS.INVALID')}`,
        });
      } else {
        this.showModal = !this.showModal;
      }
    },

    async addAlias() {
      try {
        await postUserAlias(this.detailedGroup.slug, this.alias);
        this.$notify({
          group: 'memberships_system',
          type: 'success',
          duration: 5000,
          text: `${this.$t('APP.GROUPS.ALIAS.POST.SUCCESS')}`,
        });
        setTimeout(() => {
          this.$router.go(0);
        }, 1500);
      } catch (error: any) {
        switch (error.response.data.code) {
          case 'ALIAS_TAKEN':
            this.errorMessage = 'ERROR.GROUP.ALIAS.TAKEN';
            break;
          case 'ALIAS_TOO_LONG':
            this.errorMessage = 'ERROR.GROUP.ALIAS.TOOLONG';
            break;
          default:
            this.errorMessage = 'ERROR.GROUP.POST.ALIAS';
            break;
        }
        throw error;
      }
      this.toggleAliasModal();
    },

    async fetchAlias() {
      return getUserAlias(this.detailedGroup.slug);
    },

    isValidAlias: function (alias: string) {
      var re = /^[a-z\d](?:[a-z\d_-]*[a-z\d])?$/;
      return re.test(alias);
    },

    createAlias() {
      return `"${this.detailedGroup.gsuite_prefix.toLowerCase()}-${
        this.alias
      }@ntnui.no"`;
    },

    aliasQuery() {
      return `${this.$t(
        'APP.GROUPS.ALIAS.MODAL.1'
      )} ${this.createAlias()} ${this.$t('APP.GROUPS.ALIAS.MODAL.2')} `;
    },
  },

  async created() {
    try {
      this.$data.user_alias = await this.fetchAlias();
    } catch (error) {
      this.$notify({
        group: 'memberships_system',
        type: 'error',
        duration: 5000,
        text: `${this.$t('ERROR.GROUP.FETCH.ALIAS')}`,
      });
    }
  },
});
