import vue from 'vue';
import AppVue from './App.vue';
import router from './router';
import i18n from './i18n';
import Notifications from 'vue-notification';
import store from './store/store';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import * as Sentry from '@sentry/browser';
vue.config.productionTip = false;

vue.config.errorHandler = function (err, vm, info) {
  Sentry.withScope(function (scope: any) {
    scope.setTag('tag', process.env.VUE_APP_ROOT_API);
    scope.setLevel('critical error');
    Sentry.captureException(err);
    Sentry.captureMessage(info);
  });
};

Sentry.init({
  dsn: process.env.SENTRY_API_FRONTEND,
});
axios.defaults.baseURL = 'http://localhost:8000';

const BASEURL = process.env.VUE_APP_ROOT_API;

axios.defaults.baseURL = 'http://localhost:8000';

if (typeof BASEURL !== 'undefined') {
  axios.defaults.baseURL = BASEURL;
}

vue.use(Notifications);
vue.use(VueCookies);

new vue({
  i18n,
  router,
  store,
  render: (h) => h(AppVue),
}).$mount('#app');
