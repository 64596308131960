
import {
  getAdmissionStatus,
  getGroupAdmission,
} from '@/service/admissionService';
import Banner from './Banner.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'SprintAdmissionBanner',
  components: {
    banner: Banner,
  },
  data() {
    return {
      sprintHasAdmission: false,
    };
  },
  async created() {
    const admissionStatus = await getAdmissionStatus();
    if (admissionStatus == 'open') {
      const admissions = await getGroupAdmission();
      if (admissions != null) {
        this.sprintHasAdmission = admissions.some(
          (admission) =>
            admission.slug == 'sprint' && admission.accepts_admissions
        );
      }
    }
    // eslint-disable-next-line no-console
    console.log(
      '%cLaget av %cNTNUI Sprint',
      'font-weight: bold; font-size: 1rem;color: yellow;',
      'font-weight: bold; padding-bottom: 10px; padding-right: 10px; font-size: 3rem;color: yellow; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 green , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)'
    );
    if (this.sprintHasAdmission) {
      // eslint-disable-next-line no-console
      console.log(
        '%cSnoker rundt du? Det liker vi. Sprint ser etter nye medlemmer.',
        'font-weight: bold; font-size: 1rem; color: yellow;',
        ''
      );
      // eslint-disable-next-line no-console
      console.log(
        '%cSend oss noen ord på opptak.ntnui.no :D',
        'font-weight: bold; font-size: 1rem; color: yellow;',
        ''
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(
        '%cSnoker rundt du? Det liker vi. Sprint ser alltid etter flinke folk.',
        'font-weight: bold; font-size: 1rem; color: yellow;',
        ''
      );
      // eslint-disable-next-line no-console
      console.log(
        '%cSend oss noen ord på sprint-leder@ntnui.no :D',
        'font-weight: bold; font-size: 1rem; color: yellow;',
        ''
      );
    }
  },
});
