
import Input from '@/components/forms/Input.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import GlobalError from '@/components/GlobalError.vue';
import { changePassword } from '@/service/loginService';
import { IChangePasswordPayload } from '@/types/user';
import store from '@/store/store';

import Vue from 'vue';
import { IUser } from '@/store/types';

export default Vue.extend({
  name: 'ChangePassword',
  components: {
    'form-input': Input,
    'ntnui-button-submit': ButtonSubmit,
    'global-error': GlobalError,
  },
  data() {
    return {
      changePass: {
        old_password: '',
        password_1: '',
        password_2: '',
      },
      errorMessage: '',
      loading: false,
      isError: false,
      user: {} as IUser,
    };
  },
  created() {
    this.user = store.getters['user/getUser'];
  },
  computed: {
    oldPasswordEmpty(): boolean {
      return !this.changePass.old_password;
    },
    passwordsEqual(): boolean {
      return !(this.changePass.password_1 === this.changePass.password_2);
    },
    passwordLength(): boolean {
      return this.changePass.password_1.length < 8;
    },
  },
  methods: {
    timeoutError() {
      this.isError = true;
      setTimeout(() => {
        this.isError = false;
      }, 5000);
    },
    createFetchErrorMessage(error: string) {
      let errorCodeSplit = error.split('_');
      let getLocalesMatchingCode = errorCodeSplit[errorCodeSplit.length - 1];
      this.errorMessage = this.$t(
        'ERROR.PROFILE.PASSWORD.CHANGE.' + getLocalesMatchingCode.toUpperCase()
      ).toString();
    },
    async resetPassword() {
      if (this.oldPasswordEmpty) {
        this.$data.errorMessage = this.$t(
          'ERROR.PROFILE.PASSWORD.CHANGE.OLD.EMPTY'
        );
        this.timeoutError();
      } else if (this.passwordsEqual) {
        this.$data.errorMessage = this.$t(
          'ERROR.PROFILE.PASSWORD.CHANGE.NOT_EQUAL'
        );
        this.timeoutError();
      } else if (this.passwordLength) {
        this.$data.errorMessage = this.$t(
          'ERROR.PROFILE.PASSWORD.CHANGE.LENGTH'
        );
        this.timeoutError();
      } else if (!this.loading) {
        try {
          this.loading = true;

          const payload: IChangePasswordPayload = {
            first_name: this.$data.user.first_name,
            last_name: this.$data.user.last_name,
            gender: this.$data.user.gender,
            email: this.$data.user.email,
            phone_number: this.$data.user.phone_number,
            address: this.$data.address,
            zip_code: this.$data.zipCode,
            date_of_birth: this.$data.user.date_of_birth,
            old_password: this.$data.changePass.old_password,
            password_1: this.$data.changePass.password_1,
            password_2: this.$data.changePass.password_2,
            access_id: this.$data.user.access_id,
          };

          await changePassword(payload);
          // Clear all form-data
          this.$data.changePass = {
            old_password: '',
            password_1: '',
            password_2: '',
          };
          this.$notify({
            group: 'memberships_system',
            type: 'success',
            duration: 5000,
            text: `${this.$t('APP.PROFILE.PASSWORD.CHANGE.SUCCESS')}`,
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 1500);
        } catch (error: any) {
          this.createFetchErrorMessage(error.response.data.code);
          this.timeoutError();
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
