
import Vue from 'vue';
export default Vue.extend({
  name: 'NtnuiFooterComponent',
  data() {
    return {
      icons: [
        {
          name: 'fab fa-facebook',
          url: 'https://www.facebook.com/ntnuisprint/',
          target: '_blank',
          description: 'facebook',
        },
        {
          name: 'fab fa-instagram',
          url: 'https://www.instagram.com/ntnui_sprint/',
          target: '_blank',
          description: 'instagram',
        },
        {
          name: 'fab fa-github',
          url: 'https://github.com/ntnui',
          target: '_blank',
          description: 'github',
        },
      ],
      feedbackIcon: {
        name: 'far fa-comment-alt',
        url: '/feedback',
        description: 'feedback',
      },
      displayState: 'none',
    };
  },
  methods: {
    mouseover: function () {
      this.displayState = 'block';
    },
    mouseleave: function () {
      this.displayState = 'none';
    },
  },
  computed: {
    cssVars(): { [_: string]: string } {
      return {
        '--display-state': this.displayState,
      };
    },
  },
});
