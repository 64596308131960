
import Vue from 'vue';
import Editor from '@/components/forms/Editor.vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  name: 'GroupSettingsTextEditor',
  components: {
    'ntnui-editor': Editor,
  },
  props: {
    englishDescription: String,
    norwegianDescription: String,
  },

  data() {
    return {
      selectedLanguageText: '',
      selectedLanguage: '',
    };
  },
  created() {
    this.selectedLanguage = 'nb';
    this.selectedLanguageText = this.norwegianDescription;
  },

  computed: {
    description(): TranslateResult {
      return this.selectedLanguage == 'en'
        ? this.$t('APP.GROUPS.LONG_DESCRIPTION_PLACEHOLDER.EN')
        : this.$t('APP.GROUPS.LONG_DESCRIPTION_PLACEHOLDER.NO');
    },
  },

  methods: {
    updateTextObject(content: string) {
      this.$emit('change', { value: content, locale: this.selectedLanguage });
    },
    setActiveLanguage(selected: string) {
      this.selectedLanguage = selected;
      if ('en' === selected) {
        this.selectedLanguageText = this.englishDescription;
      } else {
        this.selectedLanguageText = this.norwegianDescription;
      }
    },
  },
});
