import request from './request';
import store from '@/store/store';
import {
  IChangePasswordPayload,
  ISendResetPasswordEmailPayload,
  IUser,
  IContract,
  IChangeUserInfoPayload,
  ISendActivateUserSMSPayload,
} from '@/types/user';

export async function fetchUser(): Promise<IUser> {
  const data: IUser = await request({
    url: `/users/profile/`,
  });
  await store.dispatch('user/userLoaded', data);
  return data;
}

interface IChangePasswordResponse {
  info: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  register_date: string;
  contracts: IContract[];
  contract_expity_date: string;
  address: string;
  zip_code: string;
  access_id?: string;
}

export async function changePassword(
  changePasswordPayload: IChangePasswordPayload
): Promise<IChangePasswordResponse> {
  return request({
    method: 'PUT',
    url: '/users/profile/',
    data: changePasswordPayload,
  });
}

export async function changeUserInfo(
  changeUserInfoPayload: IChangeUserInfoPayload
): Promise<IChangePasswordResponse> {
  return request({
    method: 'PUT',
    url: '/users/profile/',
    data: changeUserInfoPayload,
  });
}

export interface IVerificationResponse {
  phone_number?: string;
  token?: string;
  detail?: string;
}

export async function verifyUserBySMS(
  payload: ISendActivateUserSMSPayload
): Promise<IVerificationResponse> {
  return request({
    method: 'POST',
    url: '/users/',
    data: payload,
  });
}

interface IResetPasswordResponse {
  detail: string;
}

export async function sendResetPasswordEmail(
  payload: ISendResetPasswordEmailPayload
): Promise<IResetPasswordResponse> {
  return request({
    method: 'POST',
    url: '/users/reset-password/send-token/',
    data: payload,
  });
}

export async function resetPassword(
  token: string,
  password: string
): Promise<IResetPasswordResponse> {
  return request({
    method: 'POST',
    url: `/users/reset-password/`,
    data: { password, token },
  });
}
