import request from './request';
import {
  IGroupRelation,
  IChangeGroupMembershipRequest,
  IChangeGroupMemberhipResponse,
} from '@/types/groupMembers';

interface IResponseDetail {
  detail: string;
}

export async function changeGroupMembership(
  groupName: string,
  membership_id: number,
  data: IChangeGroupMembershipRequest
): Promise<IChangeGroupMemberhipResponse> {
  return request({
    method: 'PUT',
    url: `/groups/${groupName}/memberships/${membership_id}/`,
    data,
  });
}

export async function removeMemberFromGroup(
  groupName: string,
  membership_id: number
): Promise<IResponseDetail> {
  return request({
    method: 'DELETE',
    url: `/groups/${groupName}/memberships/${membership_id}/`,
  });
}

export async function removeGroupInvitation(
  groupRelation: IGroupRelation
): Promise<IResponseDetail> {
  return request({
    method: 'DELETE',
    url: `/groups/${groupRelation.groupName}/invites/${groupRelation.inquiry_id}/`,
  });
}

export async function removeGroupRequest(
  groupRelation: IGroupRelation
): Promise<IResponseDetail> {
  return request({
    method: 'DELETE',
    url: `/groups/${groupRelation.groupName}/requests/${groupRelation.inquiry_id}/`,
  });
}

export async function acceptGroupRequest(
  groupRelation: IGroupRelation
): Promise<IResponseDetail> {
  return request({
    method: 'PUT',
    url: `/groups/${groupRelation.groupName}/requests/${groupRelation.inquiry_id}/`,
  });
}

export async function acceptInvitation(id: number): Promise<IResponseDetail> {
  return request({
    method: 'PUT',
    url: `/groups/invites/${id}/`,
  });
}

export async function rejectInvitation(id: number): Promise<IResponseDetail> {
  return request({
    method: 'DELETE',
    url: `/groups/invites/${id}/`,
  });
}
