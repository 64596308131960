
import Vue from 'vue';

export default Vue.extend({
  name: 'FormSwitch',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
});
