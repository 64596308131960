
import Vue from 'vue';

export default Vue.extend({
  name: 'Searchfield',
  props: {
    value: {
      type: String,
      default: '',
    },
    input: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    placeholderText: {
      type: String,
      default: '',
    },
    sideMargin: {
      type: Boolean,
      default: true,
    },
  },
});
