
import Vue from 'vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import GlobalError from '@/components/GlobalError.vue';
import { ISendResetPasswordEmailPayload } from '@/types/user';
import { sendResetPasswordEmail } from '@/service/loginService';

export default Vue.extend({
  name: 'LoginResetPasswordForm',
  components: {
    'ntnui-button-submit': ButtonSubmit,
    'ntnui-button-link': ButtonLink,
    'form-phone-input': PhoneInput,
    'global-error': GlobalError,
  },
  data() {
    return {
      phoneNumber: '',
      phoneNumberValid: false,
      error: '',
      loading: false,
      errorCode: '',
      errorMessage: '',
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.phoneNumberValid && !this.loading) {
        this.error = `${this.$t('ERROR.RESET.USER.INVALID.PHONE')}`;
        setTimeout(() => (this.error = ''), 8000);
      } else if (!this.loading) {
        const payload: ISendResetPasswordEmailPayload = {};
        payload.phone_number = this.phoneNumber;
        try {
          this.loading = true;
          await sendResetPasswordEmail(payload);
          this.$notify({
            group: 'memberships_system',
            type: 'success',
            duration: 8000,
            text: `${this.$t('APP.RESET.USER.EMAIL.SENT')}`,
          });
        } catch (error: any) {
          this.errorCode = error.response.status;
          this.errorMessage = 'ERROR.RESET.PASSWORD.' + this.errorCode;
          this.$notify({
            group: 'memberships_system',
            type: 'error',
            duration: 8000,
            text: `${this.$t(this.errorMessage)}`,
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
});
