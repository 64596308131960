
import Vue from 'vue';
import Input from '@/components/forms/Input.vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import GlobalError from '@/components/GlobalError.vue';
import { IUser } from '@/types/user';
import { changeUserInfo } from '@/service/loginService';
import { IChangeUserInfoPayload } from '@/types/user';
import { emptyStr } from '@/utils/helpers';
import { getFormatedDateString } from '@/utils/date';
import { fetchUser } from '@/service/loginService';

export default Vue.extend({
  name: 'RegisterUserForm',
  components: {
    'form-input': Input,
    'ntnui-button-submit': ButtonSubmit,
    'ntnui-button-link': ButtonLink,
    'global-error': GlobalError,
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      gender: '',
      phone_number: '',
      email: '',
      address: '',
      zip_code: '',
      date_of_birth: '',
      student_number: '',
      loading: false,
      errorMessage: '',
      has_first_name: true,
      has_last_name: true,
      user: {} as IUser,
      isError: false,
      currentDate: getFormatedDateString(new Date()),
      gender_options: [
        { label: 'MALE', value: 'M' },
        { label: 'FEMALE', value: 'F' },
        { label: 'OTHER', value: '-' },
      ],
    };
  },
  async created() {
    try {
      this.user = await fetchUser();
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      this.gender = this.user.gender;
      this.phone_number = this.user.phone_number;
      this.email = this.user.email;
      this.address = this.user.address;
      this.zip_code = this.user.zip_code;
      this.date_of_birth = this.user.date_of_birth;
      this.has_first_name = this.user.first_name.length > 0;
      this.has_last_name = this.user.last_name.length > 0;
      if (this.user.access_id) {
        this.student_number = this.user.access_id;
      }
    } catch (error: any) {
      this.$data.errorMessage =
        this.$t('APP.REGISTER.USER.FETCH.ERROR') +
        error.response.data.non_field_errors[0];
    }
  },
  computed: {
    componentIsOnEdit(): boolean {
      return (
        this.$router.currentRoute.fullPath ===
        '/profile/settings/userinformation'
      );
    },
    firstNameValid(): boolean {
      return !emptyStr(this.first_name);
    },
    lastNameValid(): boolean {
      return !emptyStr(this.last_name);
    },
    phoneNumberValid(): boolean {
      return !emptyStr(this.phone_number);
    },
    emailValid(): boolean {
      const emailRegex = /[\w!#$%&'*+=?^_`{|}~\.\-\/]+@\w+[.\w]+/;
      const isValidEmail = emailRegex.test(this.email);

      return !emptyStr(this.email) && isValidEmail;
    },
    emailDomainNTNUIValid(): boolean {
      const ntnuiRegex = /.+@ntnui.[\w]+/;
      const isNtnuiEmail = ntnuiRegex.test(this.email);

      return !isNtnuiEmail;
    },
    emailDomainNTNUValid(): boolean {
      const ntnuRegex = /@([\w]*[.])*ntnu+[.][\w]+/;
      const isNtnuEmail = ntnuRegex.test(this.email);

      return !isNtnuEmail;
    },
    zipCodeValid(): boolean {
      return !emptyStr(this.zip_code) && this.zip_code.length == 4;
    },
    addressValid(): boolean {
      return !emptyStr(this.address);
    },
    dateOfBirthValid(): boolean {
      return !emptyStr(this.date_of_birth);
    },
    studentNumValid(): boolean {
      if (this.student_number) {
        const studNum = Number(this.student_number);
        return (
          (this.student_number?.length === 6 ||
            this.student_number?.length === 10) &&
          isFinite(studNum)
        );
      }
      return true;
    },
  },
  methods: {
    timeoutError() {
      this.isError = true;
      setTimeout(() => {
        this.isError = false;
      }, 5000);
    },
    routeToProfile() {
      if (this.componentIsOnEdit) {
        setTimeout(() => {
          window.location.replace('/profile/settings');
        }, 1500);
      } else {
        setTimeout(() => {
          window.location.replace('/profile');
        }, 1500);
      }
    },

    isFormValid() {
      const toValidate = [
        [this.firstNameValid, 'ERROR.PROFILE.FIRSTNAME.EMPTY'],
        [this.lastNameValid, 'ERROR.PROFILE.LASTNAME.EMPTY'],
        [this.phoneNumberValid, 'ERROR.PROFILE.PHONENUMBER.EMPTY'],
        [this.emailValid, 'ERROR.PROFILE.EMAIL.FORMAT'],
        [this.emailDomainNTNUIValid, 'ERROR.PROFILE.EMAIL.DOMAIN.NTNUI'],
        [this.emailDomainNTNUValid, 'ERROR.PROFILE.EMAIL.DOMAIN.NTNU'],
        [this.zipCodeValid, 'ERROR.PROFILE.ZIPCODE.LENGTH'],
        [this.addressValid, 'ERROR.PROFILE.ADDRESS.LENGTH'],
        [this.dateOfBirthValid, 'ERROR.PROFILE.DATEOFBIRTH.FORMAT'],
        [this.studentNumValid, 'ERROR.PROFILE.STUDENT.NUM.LENGTH'],
      ];
      for (const [isValid, errorMsg] of toValidate) {
        if (!isValid) {
          this.$data.errorMessage = this.$t(`${errorMsg}`);
          return false;
        }
      }
      return true;
    },
    async changeData() {
      if (!this.isFormValid()) {
        this.timeoutError();
      } else if (!this.loading) {
        try {
          this.loading = true;

          const payload: IChangeUserInfoPayload = {
            first_name: this.first_name,
            last_name: this.last_name,
            gender: this.gender,
            email: this.email,
            phone_number: this.phone_number,
            address: this.address,
            zip_code: this.zip_code,
            date_of_birth: this.date_of_birth,
            access_id: this.student_number,
          };
          await changeUserInfo(payload);
          this.$notify({
            group: 'memberships_system',
            type: 'success',
            duration: 1500,
            text: `${this.$t('APP.REGISTER.SUBMIT.SUCCESS')}`,
          });
          this.routeToProfile();
        } catch (error: any) {
          this.errorMessage =
            this.$t('ERROR.PROFILE.PASSWORD.CHANGE.TITLE') +
            error.response.data.detail;
          this.timeoutError();
        } finally {
          this.errorMessage = '';
          this.loading = false;
        }
      }
    },
  },
});
