
import Vue from 'vue';
import Button from '@/components/Buttons/Button.vue';

export default Vue.extend({
  name: 'NtnuiButtonLink',
  components: {
    'ntnui-button': Button,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
});
