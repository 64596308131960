
import Vue from 'vue';
import GroupContentTop from '@/views/Group/GroupContentTop.vue';
import GroupMembers from '@/views/Group/GroupMembers/GroupMembers.vue';
import GroupSettings from '@/views/Group/GroupSettings/GroupSettings.vue';
import GroupFrontpage from '@/views/Group/GroupFrontpage/GroupFrontpage.vue';
import GroupVolunteers from '@/views/Group/GroupVolunteers/GroupVolunteers.vue';
import GroupMemberAlias from '@/views/Group/GroupMemberAlias/GroupMemberAlias.vue';
import GroupMembershipRegistering from '@/views/Group/GroupMembershipRegistering/MembershipRegistering.vue';
import { IDetailedGroup } from '@/types/group';

export default Vue.extend({
  name: 'GroupContent',
  components: {
    GroupMembers,
    GroupContentTop,
    GroupSettings,
    GroupFrontpage,
    GroupVolunteers,
    GroupMemberAlias,
    GroupMembershipRegistering,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      default: undefined,
    },
    refreshGroupData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      activePage: 'frontpage' as String,
    };
  },
  computed: {
    member(): boolean {
      return this.detailedGroup.member ? true : false;
    },
  },
  methods: {
    setActiveGroupPage(page: string) {
      this.activePage = page;
      if (page !== 'members') {
        this.refreshGroupData();
      }
    },
  },
});
