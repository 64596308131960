
import Vue from 'vue';
import NavbarItemVue from './NavbarItem.vue';
import NavbarLanguageSelectorVue from './NavbarLanguageSelector.vue';
import logout from '@/utils/logout';
import { eventBus } from '@/utils/eventBus';
import { IUser } from '@/types/user';
import { INavItem } from '@/types/navbar';
export default Vue.extend({
  name: 'NavBar',
  components: {
    'nav-item': NavbarItemVue,
    'lang-select': NavbarLanguageSelectorVue,
  },
  data() {
    return {
      scrollLength: 0,
      viewLogin: false,
      isMenuOpen: false,
      showMenu: false,
    };
  },
  computed: {
    getUser(): IUser {
      return this.$store.getters['user/getUser'];
    },
    isLoggedIn(): boolean {
      return this.$store.getters['auth/getToken'];
    },
    groups(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.GROUPS'),
        path: '/groups',
        icon: ['fa', 'fa-users'],
      };
    },
    committees(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.COMMITTEES'),
        path: '/committees',
        icon: ['fa', 'fa-users-cog'],
      };
    },
    faq(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.FAQ'),
        path: 'https://ntnui.slab.com/public/posts/faq-medlem-ntnui-no-9lt25rad/',
        icon: ['fa', 'fa-question'],
      };
    },
    refund(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.REFUND'),
        path: 'https://refusjon.ntnui.no/',
        icon: ['fa', 'fa-receipt'],
      };
    },
    travelRefund(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.TRAVELREFUND'),
        path: 'https://reise.ntnui.no/',
        icon: ['fa', 'fa-plane'],
      };
    },
    feedback(): INavItem {
      return {
        name: this.$t('APP.NAVITEMS.FEEDBACK'),
        path: '/feedback',
        icon: ['fa', 'fa-comment-alt'],
      };
    },
  },
  created() {
    eventBus.$on('toggleLoginPopup', () => {
      this.$router.push('/login').catch(() => {});
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleLogout: function () {
      logout();
    },
    toggleMenu: function () {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu: function () {
      this.isMenuOpen = false;
    },
    handleScroll: function () {
      this.scrollLength = window.scrollY;
    },
  },
});
