
import Vue from 'vue';
export default Vue.extend({
  async created() {},
  computed: {
    isWinter(): boolean {
      let month = new Date().getMonth();
      if (month == 0 || month == 11) return true;
      return false;
    },
  },
});
