
import Vue from 'vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import GlobalError from '@/components/GlobalError.vue';

import { sendGroupInviteRequest } from '@/service/groupService';

export default Vue.extend({
  name: 'GroupMembersInvitationForm',
  components: {
    'ntnui-button-submit': ButtonSubmit,
    'form-phone-input': PhoneInput,
    'global-error': GlobalError,
  },
  props: {
    setMemberTableTab: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      phoneNumber: '',
      phoneNumberValid: false,
      loading: false,
      isError: false,
      errorMessage: '',
    };
  },
  methods: {
    async sendInvite() {
      const groupName = this.$route.params.id;
      if (!this.phoneNumberValid) {
        this.errorMessage = this.$t('ERROR.LOGIN.PHONE.INVALID').toString();
        this.isError = true;
        this.errorTimeout();
      } else if (!this.loading) {
        try {
          this.loading = true;
          await sendGroupInviteRequest(groupName, this.phoneNumber);
          await this.setMemberTableTab('invite');
          this.$notify({
            group: 'memberships_system',
            type: 'success',
            duration: 3000,
            text: this.$t('APP.BOARD.INVITATION.SENT', {
              phone: this.phoneNumber,
            }).toString(),
          });
        } catch (error: any) {
          this.errorMessage = this.$t(
            'ERROR.GROUP.INVITES.' + error.response.data.code
          ).toString();
          this.isError = true;
          this.errorTimeout();
        } finally {
          this.loading = false;
        }
      }
    },
    errorTimeout() {
      setTimeout(() => {
        this.isError = false;
      }, 5000);
    },
  },
});
