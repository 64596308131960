
import Vue from 'vue';
import Button from '@/components/Buttons/Button.vue';

export default Vue.extend({
  name: 'NtnuiButtonSubmit',
  components: {
    'ntnui-button': Button,
  },
  props: {
    primary: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
