import vue from 'vue';
import vuex, { StoreOptions } from 'vuex';
import { IRootState } from './types';
import { auth } from './auth';
import { user } from './user';

vue.use(vuex);

const store: StoreOptions<IRootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    auth,
    user,
  },
};

export default new vuex.Store<IRootState>(store);
