import i18n from '@/i18n';
import { Role } from '@/types/groupMembers';

export function getRoleTranslation(role: Role) {
  switch (role) {
    case 'leader':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.LEADER')}`;
    case 'deputy_leader':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.VICEPRESIDENT')}`;
    case 'cashier':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.CASHIER')}`;
    case 'board_member':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.BOARDMEMBER')}`;
    case 'deputy_board_member':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.DEPUTY')}`;
    case 'volunteer':
      return `${i18n.t('APP.BOARD.MEMBERS.ROLE.VOLUNTEER')}`;
    default:
      return '';
  }
}
