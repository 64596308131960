
import Vue from 'vue';
export default Vue.extend({
  name: 'ProfileSettingsUserInformation',
  props: {
    information_description: {
      type: String,
      default: '',
    },
    information_value: {
      type: String,
      default: '',
    },
  },
});
