function getButtonProps() {
  return {
    primary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    click: {
      type: Function,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showHelpIcon: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
    },
  };
}

export default getButtonProps;
