import store from '@/store/store';
import axios from 'axios';
import Vue from 'vue';
import logout from '@/utils/logout';
import i18n from '@/i18n';

function buildUrl(url: string) {
  return url.toLowerCase().split(' ').join('-');
}

export default async function request(options: any): Promise<any> {
  const headers = { ...options.headers };

  if (options.method === 'POST' || options.method === 'PUT') {
    headers['content-type'] = 'application/json';
  }
  const authToken = await store.getters['auth/getToken'];
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  const url = buildUrl(options.url);
  try {
    const response = await axios({
      url,
      method: options.method || 'GET',
      headers,
      params: options.params,
      data: options.data,
    });

    return response.data;
  } catch (error: any) {
    if (error.response?.status === 403) {
      Vue.notify({
        group: 'memberships_system',
        type: 'error',
        duration: 3000,
        text: `${i18n.t('ERROR.GENERAL.403')}`,
      });
      setTimeout(() => logout(), 3000);
    }
    throw error;
  }
}
