
import Vue from 'vue';
import NTNUIMembershipCard from './Memberships/NTNUIMembershipCard.vue';
import { getDateString } from '@/utils/date';
export default Vue.extend({
  name: 'GroupMembersTableItem',
  components: {
    'ntnui-membership-card': NTNUIMembershipCard,
  },
  data() {
    return {
      formattedRequestDate: '',
    };
  },
  created() {
    if (this.member.creation_date)
      this.formattedRequestDate = getDateString(
        new Date(this.member.creation_date)
      );
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    setMember: {
      type: Function,
      required: true,
    },
    expandedMember: {
      type: undefined,
      required: true,
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showNTNUIMembership: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    createInitials(): string {
      return `${this.member.first_name.substring(
        0,
        1
      )}${this.member.last_name.substring(0, 1)}
      `;
    },
    createName(): string {
      return `${this.member.first_name} ${this.member.last_name}`;
    },
    boardMember(): string {
      switch (this.member.type) {
        case 'leader':
          return this.$t('APP.BOARD.MEMBERS.ROLE.LEADER').toString();
        case 'deputy_leader':
          return this.$t('APP.BOARD.MEMBERS.ROLE.VICEPRESIDENT').toString();
        case 'cashier':
          return this.$t('APP.BOARD.MEMBERS.ROLE.CASHIER').toString();
        case 'board_member':
          return this.$t('APP.BOARD.MEMBERS.ROLE.BOARDMEMBER').toString();
        case 'deputy_board_member':
          return this.$t('APP.BOARD.MEMBERS.ROLE.DEPUTY').toString();
        case 'volunteer':
          return this.$t('APP.BOARD.MEMBERS.ROLE.VOLUNTEER').toString();
        default:
          return '';
      }
    },
  },
});
