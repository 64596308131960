import vue from 'vue';
import vueRouter from 'vue-router';
import Home from '@/views/Home/Home.vue';
import Login from '@/views/Auth/Login.vue';
import Group from '@/views/Group/Group.vue';
import Committees from '@/views/Group/Committees.vue';
import Profile from '@/views/Profile/Profile.vue';
import ProfileSettings from '@/views/Profile/ProfileSettings/ProfileSettings.vue';
import UserReset from '@/views/UserReset.vue';
import PageNotFound from '@/views/404Page/PageNotFound.vue';
import BugReport from '@/views/BugReport/BugReport.vue';
import RegisterUserForm from '@/views/Register/RegisterUserForm.vue';
import store from '@/store/store';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import TrainerContract from '@/views/TrainerContract/TrainerContract.vue';
import VerifyPhonenumber from '@/views/Auth/VerifyPhonenumber.vue';
import { emptyStr } from '@/utils/helpers';

vue.use(vueRouter);

const router = new vueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/register/verify',
      name: 'verify',
      component: VerifyPhonenumber,
    },
    {
      path: '/reset-password/:token',
      name: 'reset',
      component: UserReset,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { requiresAuth: true },
    },
    {
      path: '/profile/my-contracts',
      name: 'contract',
      component: TrainerContract,
      meta: { requiresAuth: true },
    },
    {
      path: '/profile/settings',
      name: 'profile/settings',
      component: ProfileSettings,
      meta: { requiresAuth: true },
    },
    {
      path: '/profile/settings/userinformation',
      name: 'profile/settings/userinformation',
      component: RegisterUserForm,
      meta: { requiresAuth: true },
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterUserForm,
      meta: { requiresAuth: true },
    },
    {
      path: '/groups',
      name: 'groups',
      component: Home,
    },
    {
      path: '/committees',
      name: 'committees',
      component: Committees,
    },
    {
      path: '/groups/:id',
      name: 'group',
      component: Group,
    },
    {
      path: '/committees/:id',
      name: 'committee',
      component: Group,
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: PageNotFound,
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: BugReport,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '*',
      redirect: '/not-found',
    },
  ],
});

const checkUserFieldsEmpty = async () => {
  try {
    const user = await store.getters['user/getUser'];
    if (
      user &&
      (emptyStr(user.first_name) ||
        emptyStr(user.last_name) ||
        emptyStr(user.phone_number) ||
        emptyStr(user.email) ||
        emptyStr(user.address) ||
        emptyStr(user.zip_code))
    ) {
      return true;
    }
  } catch (error: any) {
    return false;
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  if (
    (await store.getters['auth/getToken']) !== null &&
    to.path !== '/register' &&
    (await checkUserFieldsEmpty())
  ) {
    next({ path: '/register' });
    return;
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    (await store.getters['auth/getToken']) === null
  ) {
    next({ path: '/groups' });
  }

  if (to.path === '/login' && (await store.getters['auth/getToken']) !== null) {
    next({ path: '/profile' });
  }
  next();
});

export default router;
