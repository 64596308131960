import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IRootState, IAuthState } from '../types';

export const state: IAuthState = {
  tokens: {
    access: localStorage.getItem('a'),
    refresh: localStorage.getItem('r'),
  },
};

const namespaced: boolean = true;

export const auth: Module<IAuthState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
