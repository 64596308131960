
import Vue from 'vue';
import Button from '@/components/Buttons/Button.vue';
import eventBus from '@/utils/eventBus';
import GroupJoinLeaveModal from './GroupJoinLeaveModal.vue';

export default Vue.extend({
  name: 'GroupJoinLeave',
  components: {
    'ntnui-button': Button,
    GroupJoinLeaveModal,
  },
  props: {
    detailedGroup: {
      type: Object,
      required: true,
    },
    refreshGroupData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleJoinLeaveGroupModal() {
      if (this.$store.getters['auth/getToken'] !== null) {
        this.showModal = !this.showModal;
      } else {
        eventBus.eventBusToggleLogin();
      }
    },
  },
});
