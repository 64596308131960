
import Vue from 'vue';
import * as dateFns from 'date-fns';
import { getGroupRequest } from '@/service/groupService';
import { IGroupMembershipCardData, IDetailedGroup } from '@/types/group';
import { getDateString } from '@/utils/date';
export default Vue.extend({
  name: 'group-membership-card',
  props: {
    groupName: {
      type: String,
      required: true,
    },
    groupSlug: {
      type: String,
      required: true,
    },
  },
  data(): IGroupMembershipCardData {
    return {
      detailedGroup: {} as IDetailedGroup,
      groupExpiryDate: new Date(),
      isExpired: false,
      isNearExpired: false,
      link: '',
    };
  },
  async created() {
    this.detailedGroup = await getGroupRequest(this.groupSlug);
    if (!this.detailedGroup) return;

    this.link = `/groups/${this.detailedGroup.slug}/`;

    if (!this.detailedGroup.membership) return;

    if (this.detailedGroup.membership.group_expiry === null) {
      this.isExpired = true;
      return;
    }
    this.groupExpiryDate = new Date(this.detailedGroup.membership.group_expiry);

    const today = new Date();
    const nearExpiry = dateFns.subDays(this.groupExpiryDate, 30);
    if (today > this.groupExpiryDate) {
      this.isExpired = true;
    } else if (today > nearExpiry) {
      this.isNearExpired = true;
    }
  },
  computed: {
    expiryDateString(): string {
      return getDateString(this.groupExpiryDate);
    },
  },
});
