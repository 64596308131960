
import Vue from 'vue';
import { IDetailedGroup } from '@/types/group';

export default Vue.extend({
  name: 'GroupContentTop',
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
    setActivePage: {
      type: Function,
      required: true,
    },
    activePage: {
      type: String,
      default: 'frontpage',
    },
  },
  computed: {
    activePageHeader(): string {
      switch (this.activePage) {
        case 'members':
          return this.$t('APP.GROUPS.MEMBERS').toString();
        case 'settings':
          return this.$t('APP.GROUPS.SETTINGS').toString();
        case 'alias':
          return this.$t('APP.GROUPS.ALIAS').toString();
        case 'membership_registering':
          return this.$t('APP.GROUPS.MEMBERSHIP_REGISTERING').toString();
        default:
          return this.detailedGroup.name.toUpperCase();
      }
    },
    hasAccess(): boolean {
      return (
        this.detailedGroup.board_member_access ||
        this.detailedGroup.membership?.type == 'volunteer'
      );
    },
  },
});
