import store from '@/store/store';
import axios from 'axios';
import router from '@/router';

export default function logout(): void {
  store.dispatch('auth/deleteTokens');
  store.dispatch('user/removeUser');
  axios.defaults.headers.common['Authorization'] = '';
  router.push('/groups').catch(() => {});
}
