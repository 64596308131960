
import Vue from 'vue';
import { IDetailedGroup } from '@/types/group';
import Modal from '@/components/Modal.vue';
import { joinGroupRequest } from '@/service/groupService';
import { removeMemberFromGroup } from '@/service/groupMemberService';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';

export default Vue.extend({
  name: 'GroupJoinLeaveModal',
  components: {
    Modal,
    ButtonSpinner,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    toggleJoinLeaveGroupModal: {
      type: Function,
      required: true,
    },
    refreshGroupData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    joinLeaveGroupButtonText(): string {
      if (this.detailedGroup.member) {
        return this.$t('APP.GROUPS.BUTTON.LEAVE').toString();
      } else {
        if (this.detailedGroup.access === 'O') {
          return this.$t('APP.GROUPS.BUTTON.JOIN').toString();
        } else {
          return this.$t('APP.GROUPS.REQUESTS.JOIN').toString();
        }
      }
    },
    joinLeaveGroupDescription(): string {
      if (this.detailedGroup.member) {
        return this.$t('APP.GROUPS.BUTTON.LEAVE.DESCRIPTION').toString();
      } else {
        if (this.detailedGroup.access === 'O') {
          return this.$t('APP.GROUPS.BUTTON.JOIN.DESCRIPTION').toString();
        } else {
          return this.$t('APP.GROUPS.REQUESTS.JOIN.DESCRIPTION').toString();
        }
      }
    },
  },
  methods: {
    async joinLeaveGroup() {
      const groupName: string = this.detailedGroup.slug;
      if (this.detailedGroup.membership) {
        await removeMemberFromGroup(
          groupName,
          this.detailedGroup.membership.membership_no
        );
      } else {
        await joinGroupRequest(groupName);
        window.location.reload();
      }
      this.toggleJoinLeaveGroupModal();
      this.refreshGroupData();
    },
  },
});
