
export default {
  name: 'LoadingSpinner',
  props: {
    descriptiveText: {
      type: String,
      required: false,
      default: 'APP.LOADING',
    },
    small: {
      type: Boolean,
      required: false,
    },
  },
};
