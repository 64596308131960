
import Vue from 'vue';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';

export default Vue.extend({
  name: 'GroupMembersTableTabs',
  components: {
    GroupPageHeaderCount,
  },
  props: {
    setMemberTableTab: {
      type: Function,
      required: true,
    },
    memberTableTab: {
      type: String,
      default: 'members',
    },
    tableLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    contentPlaceholder(): string {
      switch (this.memberTableTab) {
        case 'members':
          return this.$t('APP.GROUPS.HEADER.MEMBERS').toString();
        case 'invite':
          return this.$t('APP.GROUPS.HEADER.INVITES').toString();
        case 'request':
          return this.$t('APP.GROUPS.HEADER.REQUESTS').toString();
        default:
          return '';
      }
    },
  },
});
