
import Vue from 'vue';
export default Vue.extend({
  name: 'AccordionElement',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
    };
  },
  methods: {
    expandAccordion() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
