<template>
  <ntnui-button :v-bind="$attrs" v-bind="$props" :click="localClick">
    <loading-spinner v-if="isLoading" :small="true" :descriptiveText="''" />
    <slot v-else />
  </ntnui-button>
</template>

<script>
import NtnuiButton from '@/components/Buttons/Button.vue';
import getButtonProps from '@/utils/buttonDefaultProps';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
export default {
  components: {
    NtnuiButton,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    ...getButtonProps(),
    errorMessage: {
      type: String,
      default: '',
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    getErrorMessage() {
      return this.errorMessage == ''
        ? this.$t('ERROR.UNKNOWN')
        : this.errorMessage;
    },
    getSuccessMessage() {
      return this.successMessage == ''
        ? this.$t('SUCCESS.DEFAULT')
        : this.successMessage;
    },
    notify(type, message) {
      this.$notify({
        group: 'memberships_system',
        type: type,
        duration: 5000,
        text: message,
      });
    },
    async localClick() {
      try {
        this.isLoading = true;
        await this.$props.click();
        if (this.getSuccessMessage())
          this.notify('success', this.getSuccessMessage());
      } catch {
        if (this.getErrorMessage())
          this.notify('error', this.getErrorMessage());
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
