
import Vue from 'vue';
import * as dateFns from 'date-fns';
export default Vue.extend({
  name: 'GroupListGroupCard',
  props: {
    groupSlug: {
      type: String,
      required: true,
    },
    groupExpiryDate: {
      type: String,
      default: '',
    },
    urlPath: {
      type: String,
      default: 'groups',
    },
  },
  computed: {
    link(): string {
      return `/${this.urlPath}/${this.groupSlug}/`;
    },
    isNearExpired() {
      var today = new Date();
      var expiry = new Date(this.groupExpiryDate);
      expiry = dateFns.subDays(expiry, 30);
      return today > expiry;
    },
    isExpired() {
      var today = new Date();
      var expiry = new Date(this.groupExpiryDate);
      return today > expiry;
    },
  },
});
