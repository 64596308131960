
import Vue from 'vue';
export default Vue.extend({
  name: 'Modal',
  props: {
    visible: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    closeIcon: {
      type: String,
      default: 'fas fa-times fa-2x',
    },
  },
});
