
import Vue from 'vue';
import ButtonSubmit from '@/components/Buttons/ButtonSubmit.vue';
import Input from '@/components/forms/Input.vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import GlobalError from '@/components/GlobalError.vue';
import Tooltip from '@/components/Tooltip.vue';

import { fetchUser } from '@/service/loginService';
import { fetchToken } from '@/service/auth';

import { ITokenPayload } from '@/types/tokenPayload';

export default Vue.extend({
  name: 'LoginForm',
  components: {
    'form-input': Input,
    'button-submit': ButtonSubmit,
    'form-phone-input': PhoneInput,
    'global-error': GlobalError,
    tooltip: Tooltip,
  },
  data() {
    return {
      phoneNumber: '',
      phoneNumberValid: false,
      password: '',
      passwordValid: false,
      errormessage: '',
      error: '',
      loading: false,
      isError: false,
    };
  },
  computed: {
    isValidPassword(): boolean {
      return this.password === '' || this.password.length >= 8;
    },
  },
  methods: {
    async login() {
      if (!this.phoneNumberValid && !this.loading) {
        this.error = `${this.$t('ERROR.LOGIN.PHONE.INVALID')}`;
        setTimeout(() => (this.error = ''), 5000);
      } else if (!this.isValidPassword && !this.loading) {
        this.error = `${this.$t('ERROR.LOGIN.LENGTH')}`;
        setTimeout(() => (this.error = ''), 5000);
      } else if (!this.loading) {
        const payload: ITokenPayload = {
          phone_number: this.phoneNumber,
          password: this.password,
        };
        try {
          this.loading = true;
          await fetchToken(payload);
          await fetchUser();
          this.$router.push('/profile').catch(() => {});
        } catch (error) {
          this.error = `${this.$t('ERROR.LOGIN.NO_USER')}`;
          this.isError = true;
          setTimeout(() => {
            this.error = '';
            this.isError = false;
          }, 5000);
        } finally {
          this.loading = false;
        }
      }
      this.isError = true;
      setTimeout(() => {
        this.isError = false;
      }, 5000);
    },
  },
});
