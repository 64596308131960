
import Vue from 'vue';
import Navbar from '@/views/Navbar/Navbar.vue';
import Footer from '@/components/Footer.vue';
import SprintAdmissionBanner from './components/SprintAdmissionBanner.vue';
import Snowflakes from '@/components/Snowflakes.vue';
import { refreshTokenLogic } from '@/utils/refreshToken';
import { verifyToken } from '@/service/auth';
export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
    Snowflakes,
    'ntnui-footer': Footer,
    'sprint-admission-banner': SprintAdmissionBanner,
  },
  async created() {
    await verifyToken();
  },
  async updated() {
    await refreshTokenLogic();
  },
});
