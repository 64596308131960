
import Vue from 'vue';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import LoginForm from '@/views/Auth/LoginForm.vue';

export default Vue.extend({
  name: 'Login',
  components: {
    LoginForm,
    'ntnui-button-link': ButtonLink,
  },
});
