
import Vue from 'vue';
import PrivacySection from '@/views/Profile/ProfileSettings/PrivacySection.vue';
import ChangePassword from '@/views/Profile/ProfileSettings/ChangePassword.vue';
import UserInformation from '@/views/Profile/ProfileSettings/UserInformation.vue';
import Accordion from '@/components/Accordion.vue';
import BreadcrumbsVue from '@/components/Breadcrumbs.vue';
import store from '@/store/store';
import { IUser } from '@/types/user';
import ButtonLink from '@/components/Buttons/ButtonLink.vue';
import { fetchUser } from '@/service/loginService';

export default Vue.extend({
  name: 'ProfileView',
  components: {
    PrivacySection,
    ChangePassword,
    BreadcrumbsVue,
    Accordion,
    UserInformation,
    'ntnui-button-link': ButtonLink,
  },
  data() {
    return {
      user: store.getters['user/getUser'] as IUser,
      previousRouteData: [],
    };
  },
  async mounted() {
    if (!this.user) {
      this.user = await fetchUser();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.previousRouteData = [from.path, from.name];
    });
  },
});
