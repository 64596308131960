
import Vue from 'vue';
import Input from '@/components/forms/Input.vue';
import PhoneNumber from 'awesome-phonenumber';
import CountryCodes from './CountryCodes.json';

export default Vue.extend({
  name: 'FormPhoneInput',
  components: {
    'form-input': Input,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    phoneNumberValid: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryCode: 'no',
      number: '',
      countries: CountryCodes,
    };
  },
  computed: {
    isValidPhone(): boolean {
      const phoneNumber = new PhoneNumber(this.number, this.countryCode);
      this.$emit('update:phoneNumber', phoneNumber.getNumber());
      this.$emit('update:phoneNumberValid', phoneNumber.isValid());
      return this.number === '' || phoneNumber.isValid();
    },
  },
});
