
import Vue from 'vue';

export default Vue.extend({
  name: 'language-changer',
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      langs: ['no', 'en'] as string[],
      countryImages: {
        no: require('@/assets/images/flags/norway-flag.png'),
        en: require('@/assets/images/flags/united-kingdom-flag.png'),
      },
    };
  },
  methods: {
    getLanguageCode(): string {
      if (['en', 'en-us'].includes(this.$i18n.locale.toLowerCase())) {
        return 'en';
      } else {
        return 'no';
      }
    },
    changeLanguage(): void {
      const language =
        this.getLanguageCode() === this.langs[0]
          ? this.langs[1]
          : this.langs[0];
      localStorage.setItem('language', language);
      this.$i18n.locale = language;
    },
  },
});
