var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.inner_card},[(_vm.isExpired)?_c('em',{class:[
      'fa',
      'fa-exclamation-triangle',
      _vm.isGroupMemberCard ? _vm.$style.icon__groupMemberCardSize : _vm.$style.icon,
      _vm.$style.icon__red,
    ]}):(_vm.isNearExpired)?_c('em',{class:[
      'fa',
      'fa-exclamation-triangle',
      _vm.isGroupMemberCard ? _vm.$style.icon__groupMemberCardSize : _vm.$style.icon,
      _vm.$style.icon__yellow,
    ]}):_c('em',{class:[
      'fa',
      'fa-check-circle',
      _vm.isGroupMemberCard ? _vm.$style.icon__groupMemberCardSize : _vm.$style.icon,
      _vm.$style.icon__green,
    ]}),_c('h4',{class:[
      _vm.$style.card_name_text,
      _vm.isGroupMemberCard && _vm.$style.groupMemberCardSize,
    ]},[_vm._v(" "+_vm._s(_vm.membershipName)+" ")]),(_vm.isExpired)?_c('p',{class:[
      _vm.$style.card_text,
      _vm.isGroupMemberCard && _vm.$style.groupMemberCardSize,
    ]},[_vm._v(" "+_vm._s(_vm.$t('APP.MEMBERSHIPS_LIST.EXPIRED'))+" "),_c('tooltip',{attrs:{"message":_vm.$t('APP.PROFILE.MEMBERSHIP.TOOLTIP')}})],1):(_vm.isNearExpired)?_c('p',{class:[
      _vm.$style.card_text,
      _vm.isGroupMemberCard && _vm.$style.groupMemberCardSize,
    ]},[_vm._v(" "+_vm._s(_vm.$t('APP.MEMBERSHIPS_LIST.ALMOST_EXPIRED') + _vm.expiryDateString)+" ")]):_c('p',{class:[
      _vm.$style.card_text,
      _vm.isGroupMemberCard && _vm.$style.groupMemberCardSize,
    ]},[_vm._v(" "+_vm._s(_vm.$t('APP.MEMBERSHIPS_LIST.VALID') + _vm.expiryDateString)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }