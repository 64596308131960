
import MembershipRegisterComponent from './MembershipRegisterComponent.vue';
import GroupPageHeaderCount from '@/components/GroupPageHeaderCount.vue';
import {
  IDetailedGroup,
  IGroupMembershipRecordParams,
  IGroupMembershipRecord,
} from '@/types/group';
import { IDOMEvent } from '@/types/events';
import Vue from 'vue';
import {
  downloadMemberships,
  getGroupMembershipRecords,
  putGroupMembershipRecord,
} from '@/service/groupService';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SelectVue from 'vue-select';
import ButtonSpinner from '@/components/Buttons/ButtonSpinner.vue';

export default Vue.extend({
  components: {
    'group-page-header-count': GroupPageHeaderCount,
    'loading-spinner': LoadingSpinner,
    MembershipRegisterComponent,
    'vue-select': SelectVue,
    ButtonSpinner,
  },
  props: {
    detailedGroup: {
      type: Object as () => IDetailedGroup,
      required: true,
    },
  },
  data() {
    return {
      registeredUsers: [] as IGroupMembershipRecord[],
      nonRegisteredUsers: [] as IGroupMembershipRecord[],
      currentOpenRegistered: 0,
      currentOpenNonRegistered: 0,
      loading: false,
      successMessage: '',
      errorMessage: '',
      first_time: false,
      switchYear: false,
      chosenYear: 0,
      availableYears: [] as number[],
      nextPageParamsRegistered: undefined as
        | undefined
        | IGroupMembershipRecordParams,
      nextPageParamsNonRegistered: undefined as
        | undefined
        | IGroupMembershipRecordParams,
      countRegistered: 0,
      countNonRegistered: 0,
      countTotal: 0,
      scrollRegisteredComplete: false,
      scrollNonRegisteredComplete: false,
      currentNonRegLength: 0,
      currentRegLength: 0,
      loadingMoreNonRegUsers: false,
      loadingMoreRegUsers: false,
    };
  },
  async created() {
    await this.findAvailableYear().then(async () => {
      await this.fetchUsers(this.chosenYear);
    });
    this.first_time = true;
  },
  methods: {
    async fetchUsers(chosenYear: number) {
      try {
        this.loading = true;
        const initialNonRegisteredUsers = await getGroupMembershipRecords(
          this.detailedGroup.slug,
          { year: chosenYear, is_registered_in_club_admin: false }
        );

        const initialRegisteredUsers = await getGroupMembershipRecords(
          this.detailedGroup.slug,
          { year: chosenYear, is_registered_in_club_admin: true }
        );

        this.nextPageParamsNonRegistered =
          initialNonRegisteredUsers.next &&
          this.parseUrlString(initialNonRegisteredUsers.next);

        this.nextPageParamsRegistered =
          initialRegisteredUsers.next &&
          this.parseUrlString(initialRegisteredUsers.next);

        this.countNonRegistered = initialNonRegisteredUsers.count;
        this.countRegistered = initialRegisteredUsers.count;
        this.countTotal =
          initialNonRegisteredUsers.count + initialRegisteredUsers.count;

        this.registeredUsers = initialRegisteredUsers.results;
        this.nonRegisteredUsers = initialNonRegisteredUsers.results;

        this.currentNonRegLength = this.nonRegisteredUsers.length;
        this.currentRegLength = this.registeredUsers.length;
      } catch (error) {
        this.loading = false;
        this.$notify({
          group: 'memberships_system',
          type: 'error',
          duration: 3000,
          text: this.$t('APP.BOARD.MEMBERSHIP_REGISTERING.ERROR').toString(),
        });
      }
    },
    async fetchMoreUsers(params?: IGroupMembershipRecordParams) {
      const users = await getGroupMembershipRecords(
        this.detailedGroup.slug,
        params
      );
      if (!params?.is_registered_in_club_admin) {
        this.nextPageParamsNonRegistered =
          users.next && this.parseUrlString(users.next);
        this.nonRegisteredUsers = users.results;
      } else {
        this.nextPageParamsRegistered =
          users.next && this.parseUrlString(users.next);
        this.registeredUsers = users.results;
      }
      this.currentNonRegLength = this.nonRegisteredUsers.length;
      this.currentRegLength = this.registeredUsers.length;
    },
    async loadMoreUsers(params?: IGroupMembershipRecordParams) {
      if (!params?.is_registered_in_club_admin) {
        this.loadingMoreNonRegUsers = true;
      } else {
        this.loadingMoreRegUsers = true;
      }

      const users = await getGroupMembershipRecords(
        this.detailedGroup.slug,
        params
      );
      if (
        !params?.is_registered_in_club_admin &&
        this.nonRegisteredUsers.length < this.countNonRegistered
      ) {
        this.nextPageParamsNonRegistered =
          users.next && this.parseUrlString(users.next);

        for (const result of users.results) {
          if (!this.nonRegisteredUsers.includes(result)) {
            this.nonRegisteredUsers.push(result);
          }
        }
        this.currentNonRegLength = this.nonRegisteredUsers.length;

        if (this.currentNonRegLength === this.countNonRegistered) {
          this.scrollNonRegisteredComplete = true;
        }
        this.loadingMoreNonRegUsers = false;
      } else if (
        params?.is_registered_in_club_admin &&
        this.registeredUsers.length < this.countRegistered
      ) {
        this.nextPageParamsRegistered =
          users.next && this.parseUrlString(users.next);

        for (const result of users.results) {
          if (!this.registeredUsers.includes(result)) {
            this.registeredUsers.push(result);
          }
        }
        this.currentRegLength = this.registeredUsers.length;

        if (this.currentRegLength === this.countRegistered) {
          this.scrollRegisteredComplete = true;
        }
        this.loadingMoreRegUsers = false;
      }
    },
    async changeYearDropdownValue(year: number) {
      this.loading = true;
      this.switchYear = true;
      this.chosenYear = year;
      this.registeredUsers = [];
      this.nonRegisteredUsers = [];
      this.nextPageParamsRegistered = undefined;
      this.nextPageParamsNonRegistered = undefined;
      await this.fetchUsers(this.chosenYear);
      this.switchYear = false;
      this.loading = false;
    },
    async findAvailableYear() {
      const currentYear = new Date().getFullYear();
      this.availableYears.push(currentYear - 1, currentYear);
      this.chosenYear = this.availableYears[0];
    },
    async registerUser(member: IGroupMembershipRecord) {
      await putGroupMembershipRecord(
        this.detailedGroup.slug,
        member.membership_history_no,
        true
      );

      this.currentNonRegLength -= 1;
      this.currentRegLength += 1;

      this.countNonRegistered -= 1;
      this.countRegistered += 1;

      if (this.currentRegLength === this.countRegistered) {
        this.scrollRegisteredComplete = true;
      }
      if (this.currentNonRegLength === this.countNonRegistered) {
        this.scrollNonRegisteredComplete = true;
      }

      await this.fetchMoreUsers({
        page: undefined,
        page_size: this.nextPageParamsRegistered?.page_size
          ? this.currentRegLength > this.nextPageParamsRegistered?.page_size
            ? this.currentRegLength
            : this.nextPageParamsRegistered?.page_size
          : this.currentRegLength,
        year: this.chosenYear,
        is_registered_in_club_admin: true,
      });

      await this.fetchMoreUsers({
        page: undefined,
        page_size: this.nextPageParamsNonRegistered?.page_size
          ? this.currentNonRegLength >
            this.nextPageParamsNonRegistered?.page_size
            ? this.currentNonRegLength
            : this.nextPageParamsNonRegistered?.page_size
          : this.currentNonRegLength,
        year: this.chosenYear,
        is_registered_in_club_admin: false,
      });

      this.$notify({
        group: 'memberships_system',
        type: 'success',
        duration: 3000,
        text: `${member.user.first_name} ${member.user.last_name} ${this.$t(
          'APP.BOARD.MEMBERSHIP_REGISTERING.ISREGISTERED.FEEDBACK'
        )}`,
      });
    },
    async unRegisterUser(member: IGroupMembershipRecord) {
      await putGroupMembershipRecord(
        this.detailedGroup.slug,
        member.membership_history_no,
        false
      );

      this.currentNonRegLength += 1;
      this.currentRegLength -= 1;

      this.countNonRegistered += 1;
      this.countRegistered -= 1;

      if (this.currentRegLength === this.countRegistered) {
        this.scrollRegisteredComplete = true;
      }
      if (this.currentNonRegLength === this.countNonRegistered) {
        this.scrollNonRegisteredComplete = true;
      }

      await this.fetchMoreUsers({
        page: undefined,
        page_size: this.nextPageParamsNonRegistered?.page_size
          ? this.currentNonRegLength >
            this.nextPageParamsNonRegistered?.page_size
            ? this.currentNonRegLength
            : this.nextPageParamsNonRegistered?.page_size
          : this.currentNonRegLength,
        year: this.chosenYear,
        is_registered_in_club_admin: false,
      });

      await this.fetchMoreUsers({
        page: undefined,
        page_size: this.nextPageParamsRegistered?.page_size
          ? this.currentRegLength > this.nextPageParamsRegistered?.page_size
            ? this.currentRegLength
            : this.nextPageParamsRegistered?.page_size
          : this.currentRegLength,
        year: this.chosenYear,
        is_registered_in_club_admin: true,
      });

      this.$notify({
        group: 'memberships_system',
        type: 'success',
        duration: 3000,
        text: `${member.user.first_name} ${member.user.last_name} ${this.$t(
          'APP.BOARD.MEMBERSHIP_REGISTERING.ISUNREGISTERED.FEEDBACK'
        )}`,
      });
    },
    expandBoxes(membershipIndex: number, member: IGroupMembershipRecord) {
      if (member.is_registered_in_club_admin) {
        this.currentOpenNonRegistered = membershipIndex;
      }
      this.currentOpenRegistered = membershipIndex;
    },
    async onScroll(event: IDOMEvent<HTMLInputElement>, type: string) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight
      ) {
        if (
          type === 'nonRegistered' &&
          this.currentNonRegLength !== this.countNonRegistered
        ) {
          await this.loadMoreUsers({
            ...this.nextPageParamsNonRegistered,
            year: this.chosenYear,
            is_registered_in_club_admin: false,
          });
        } else if (
          type === 'registered' &&
          this.currentRegLength !== this.countRegistered
        ) {
          await this.loadMoreUsers({
            ...this.nextPageParamsNonRegistered,
            year: this.chosenYear,
            is_registered_in_club_admin: true,
          });
        }
      }
    },
    parseUrlString(urlString: string) {
      const parameterString = urlString.split('?')[1];
      return parameterString
        .split('&')
        .slice(0, 3)
        .reduce((acc, parameter) => {
          const parameterTuple = parameter.split('=');

          return { ...acc, [parameterTuple[0]]: parameterTuple[1] };
        }, {});
    },
    async downloadMembershipRegisteringList() {
      try {
        await downloadMemberships(
          this.detailedGroup.slug,
          this.chosenYear.toString()
        );
        this.successMessage =
          'APP.BOARD.MEMBERSHIP_REGISTERING.DOWNLOAD.SUCCESS';
      } catch (error: any) {
        switch (error.response.data.code) {
          case 'NO_MEMBERS_FOUND':
            this.errorMessage =
              'APP.BOARD.MEMBERSHIP_REGISTERING.DOWNLOAD.ERROR.NO_MEMBERS_FOUND';
            break;
          default:
            this.errorMessage =
              'APP.BOARD.MEMBERSHIP_REGISTERING.DOWNLOAD.ERROR';
            break;
        }
        throw error;
      }
    },
  },
});
