<template>
  <div :class="$style.notification" v-if="!bannerSeen">
    <span :class="$style.close_icon" v-on:click="hideBanner"
      ><i class="fas fa-times fa-2x" v-on:click="hideBanner"></i
    ></span>
    <h3>
      <a :class="$style.link" :href="link">{{ $t(this.content) }}</a>
    </h3>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Banner',
  created() {
    this.bannerSeen = this.$cookies.get(this.cookieKey);
  },
  data() {
    return {
      bannerSeen: false,
    };
  },
  methods: {
    hideBanner() {
      this.$cookies.set(this.cookieKey, true, this.duration);
      this.bannerSeen = true;
    },
  },
  props: {
    link: {
      type: String,
      optional: true,
    },
    content: {
      type: String,
      required: true,
    },
    cookieKey: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
  },
});
</script>
<style lang="scss" module>
.notification {
  background: $global-yellow;
  margin: rem(10) auto rem(5) auto;
  text-align: center;
  font-size: rem(20);
  padding: rem(6);
  h3 {
    color: $global-gray;
    margin: rem(12);
  }
}
.link {
  text-decoration: underline;
  color: $global-black;
  display: block;
}
.close_icon {
  float: right;
  cursor: pointer;
  font-size: rem(12);
  background: none;
  border: none;
  margin: rem(10);
}
</style>
