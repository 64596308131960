import i18n from '@/i18n';

export function getDateString(date: Date): string {
  const day = date.getDate();
  const month = i18n.t('APP.DATE.MONTH.' + date.getMonth());
  const year = date.getFullYear();
  return day + '. ' + month + ' ' + year;
}

export function getFormatedDateString(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function getWeekdayNumber(day: string): number {
  switch (day.toLowerCase()) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    case 'sunday':
      return 0;
  }
  return 0;
}
